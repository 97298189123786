:host {
	display: flex;
	align-items: center;
	justify-content: center;
}

svg.default {
	path {
	}
}

svg.white {
	path {
		fill: white;
	}
}
