@use "responsive";
@use "./global-constants" as gc;

:root {
	/* AVANTGARDE THEME */
	--kyc-darkest: #140b25;
	--kyc-dark: #2c1c4d;
	--kyc-uv-purple: #5e20ff;
	--kyc-yellow: #ffcd1d;
	--kyc-cyberpunk: #2cf2ff;

	--kyc-cybergreen: #00ffb3;
	--kyc-green: #1ee490;
	--kyc-dark-green: #17d785;

	/* OLD THEME */
	--kyc-normal-blue: #3a4be9;
	--kyc-dark-blue: #181f60;
	--kyc-dark-purple: #21114a;
	//--kyc-green: #03f682;
	//--kyc-dark-green: #09b678;

	/* MISC */
	--kyc-red: #ff4646;
	--kyc-red-darken: #d51f4f;

	//--border: rgba(24, 31, 96, 0.05);
	--border: white;

	--bg-gray: #f8f8fa;

	--border-radius-full: 999rem;
	--border-radius-light: 4px;
	--br10: 10px;
	--br12: 12px;
	--br24: 24px;
	--br42: 42px;

	--steps-padding: 2rem;
	--inactive: #d7d9df;

	--connect-button-width: 19rem;

	/*  UNIT  */
	@media #{gc.$smobile} {
		--unit: 16px;
	}

	@media #{gc.$mobile} {
		//--unit: 32px;
		--unit: 1rem;
	}

	@media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
		--unit: 4.166666666666667vw;
	}

	/*  BUTTON HEIGHT  */
	@media #{gc.$smobile}, #{gc.$mobile}, #{gc.$tablet} {
		--button-height: 3rem;
	}

	@media #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
		//--button-height: 4rem;
		//--button-height: 3.3vw;
		--button-height: 3vw;
	}

	/*
  https://www.w3schools.com/css/css3_variables_javascript.asp
   */

	/*
  --kyc-normal-blue-75: rgba(var(--kyc-normal-blue), 0.75);
  --kyc-normal-blue-50: rgba(var(--kyc-normal-blue), 0.50);
  --kyc-normal-blue-35: rgba(var(--kyc-normal-blue), 0.35);
   */

	--kyc-normal-blue-75: rgba(58, 75, 233, 0.75);
	--kyc-normal-blue-50: rgba(58, 75, 233, 0.5);
	--kyc-normal-blue-35: rgba(58, 75, 233, 0.35);
	--kyc-normal-blue-15: rgba(58, 75, 233, 0.15);

	--kyc-dark-blue-35: rgba(24, 31, 96, 0.35);
	--kyc-dark-blue-50: rgba(24, 31, 96, 0.5);
	--kyc-dark-blue-75: rgba(24, 31, 96, 0.75);

	--kyc-green-35: rgba(3, 246, 13, 0.35);
	--kyc-green-50: rgba(3, 246, 13, 0.5);
	--kyc-green-75: rgba(3, 246, 13, 0.75);

	--kyc-yellow-35: rgba(255, 205, 29, 0.35);
	--kyc-yellow-50: rgba(255, 205, 29, 0.5);
	--kyc-yellow-75: rgba(255, 205, 29, 0.75);

	--kyc-cyberpunk-35: rgba(44, 252, 255, 0.35);
	--kyc-cyberpunk-50: rgba(44, 252, 255, 0.5);
	--kyc-cyberpunk-75: rgba(44, 252, 255, 0.75);

	--kyc-red-35: rgba(255, 70, 70, 0.35);

	/* OLD FONTS */
	//--primary-font: gilroyRegular;
	//--light-font: gilroyLight;
	//--display-font: gilroyBold;

	/* NEW FONTS */
	--primary-font: machineRegular;
	--bold-font: machineBold;
	--light-font: machineLight;
	--display-font: sonarDao;
	//--display-font: sonaarLight;
}
