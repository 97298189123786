@use "@Style/base/animations";

:host {
	display: flex;
	align-items: center;
	justify-content: center;
}

svg {
	height: 100%;
}

svg.white {
	polygon {
		fill: white;
		@extend .tr2;
	}

	&:hover {
		polygon {
			fill: var(--kyc-normal-blue);
		}
	}
}
