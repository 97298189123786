@use "src/style/base";

$anim: 250ms;

#home-page {
	#hero {
		top: 60px;
		opacity: 0;
		transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);

		.container-wrapper {
			//overflow: hidden;

			&:after {
				//content: '';
				display: block;
				width: 1600px;
				height: 1600px;
				border-radius: 999rem;
				opacity: 0.3;
				background: rgb(244, 99, 99);
				background: radial-gradient(
					circle,
					rgba(244, 99, 99, 1) 0%,
					rgba(224, 227, 242, 0.13769257703081228) 60%,
					rgba(255, 255, 255, 0) 100%
				);
				position: absolute;
				left: -400px;
				top: -800px;
			}
		}

		.headline-wrapper {
			overflow: hidden;

			h1 {
				top: 120%;
				transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
				transition-delay: calc(#{$anim} * 2);
			}
		}
	}

	.cta-banner {
		top: 60px;
		opacity: 0;
		transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
		transition-delay: calc(#{$anim} * 3);

		position: relative;
		z-index: 10;

		.content {
			transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);

			h3 {
				top: 60px;
				opacity: 0;
				transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
				transition-delay: calc(#{$anim} * 4);
			}

			.usp-wrapper {
				li:first-child {
					top: 60px;
					opacity: 0;
					transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
					transition-delay: calc(#{$anim} * 5);
				}

				li:nth-child(2) {
					top: 60px;
					opacity: 0;
					transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
					transition-delay: calc(#{$anim} * 6);
				}

				li:nth-child(3) {
					top: 60px;
					opacity: 0;
					transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
					transition-delay: calc(#{$anim} * 7);
				}
			}
		}
	}

	.cta-banner {
		opacity: 0;
		top: 60px;
		transition: 0.75s cubic-bezier(0.66, 0.01, 0.17, 1);
		transition-delay: calc(#{$anim} * 4);
	}
}

#grid-page.loaded {
	#hero {
		top: 0;
		opacity: 1;

		.headline-wrapper h1 {
			top: 0;
		}
	}

	.cta-banner {
		top: 0;
		opacity: 1;
	}

	.cta-banner {
		top: 0;
		opacity: 1;

		h3 {
			top: 0;
			opacity: 1;
		}

		.usp-wrapper {
			li:first-child {
				top: 0;
				opacity: 1;
			}

			li:nth-child(2) {
				top: 0;
				opacity: 1;
			}

			li:nth-child(3) {
				top: 0;
				opacity: 1;
			}
		}
	}
}
