@use "@Style/base";

.modal-wrapper {
	position: fixed;
	z-index: 1100;
	top: 0;

	visibility: visible;
	opacity: 1;

	display: flex;
	flex-flow: column;
	justify-content: center;
	align-content: center;

	width: 100%;
	height: 100%;

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		padding: 1rem;
	}

	.modal {
		background: white;
		align-self: center;
		padding: 2rem;
		border-top: 5px solid var(--kyc-green);

		@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
			width: 600px;
		}

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			width: 100%;
		}
	}
}

.steps-wrapper {
	position: relative;
	height: 100%;

	.step {
		display: flex;
		flex-flow: column;

		/*
    position: absolute;
    width: 100%;
    height: 100%;
     */

		h2 {
			text-align: center;
			padding: 1rem 0;
			margin: 1rem 0;
		}

		h4 {
			text-align: center;
			margin: 1rem 0;

			span {
				font-family: var(--display-font);
			}
		}

		.button-wrapper {
			margin: 1rem auto;
		}

		.feedback-options {
			li {
				padding: 0.5rem 1rem 0.5rem 0.5rem;
				border: 1px solid var(--kyc-normal-blue);
				display: inline-flex;
				align-items: center;
				margin-right: 1rem;
				margin-bottom: 1rem;
				cursor: pointer;
				@extend .tr2;

				span {
					@extend p;
					color: var(--kyc-normal-blue);
					margin-left: 0.25rem;
				}

				i {
					color: var(--kyc-normal-blue);
				}

				&:hover:not(.materialIcons) {
					background: var(--kyc-normal-blue);

					i,
					span {
						color: white;
					}
				}
			}
		}

		textarea {
			padding: 0.5rem;
			border: 1px solid var(--kyc-normal-blue);
			display: inline-flex;

			font-family: var(--primary-font);
			@extend p;
			color: var(--kyc-normal-blue);
		}
	}
}

.link-button {
	display: inline-block;
	box-sizing: border-box;
	padding: 0;
	position: relative;
	color: var(--kyc-normal-blue);
	font-family: var(--primary-font);
	@extend .tr2;

	&:after {
		position: absolute;
		bottom: 0px;
		height: 9px;
		width: 100%;
		background-color: var(--kyc-normal-blue-35);
		content: "";
		display: block;
		@extend .tr2;
	}

	&:hover {
		//background-color: var(--kyc-normal-blue-35);
		color: var(--kyc-normal-blue);

		&:after {
			background: var(--kyc-normal-blue);
		}
	}
}

.status-indicator {
	padding: 10px 1rem;
	background: var(--kyc-dark-purple);
	width: 220px;
	margin: 0 auto;

	span {
		color: white;
		font-family: var(--primary-font);
	}
}

.rate-scale {
	display: flex;
	justify-content: center;

	li {
		margin: 0.5rem;
		padding: 1.5rem;
		width: 1rem;
		height: 1rem;
		border-radius: 999rem;
		background: var(--kyc-normal-blue);
		color: white;
		cursor: pointer;
		@extend h4;

		display: flex;
		align-items: center;
		justify-content: center;
		@extend .tr2;

		&:hover {
			background: var(--kyc-green);
		}
	}
}

#congrats {
	.nft {
		width: 180px;
		height: 180px;
		background: gray;
		border-radius: 999rem;
		margin: 2rem auto;
	}

	.link-button {
		margin: 0 auto;
		display: flex;
		align-items: center;

		h4 {
			margin: 0;
		}

		i {
			margin-left: 0.5rem;
		}
	}
}

#feedback-1 {
	.rate-scale {
		li {
			margin: 0.5rem;
		}
	}
}
