@use "@Style/base.scss";

#hero {
	h2 {
		padding-top: var(--unit);
	}
}

#home-page {
	.the-proposition {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		position: relative;
		height: 100%;
		z-index: 100;

		@media #{base.$smobile},
    #{base.$mobile},
    #{base.$tablet} {
			grid-column: 1 / 24;
		}

		@media #{base.$desktop} {
			grid-column: 1 / 12;
		}

		@media #{base.$widescreen},
    #{base.$fullhd} {
			grid-column: 1 / 8;
		}

		h2 {
			margin-bottom: 1rem;
		}

		h2,
		h3,
		h4 {
			color: white;
			font-weight: 400;
			z-index: 2;
			position: relative;
		}

		.kyc-button {
			position: relative;
			z-index: 2;
		}

		.form-wrapper {
			width: 100%;
			display: flex;
			flex-flow: column;

			input {
				margin-bottom: 1rem;
			}

			input,
			a {
				width: 100%;
			}
		}

		.logo-outlined {
			position: absolute;
			z-index: 1;
			height: calc(100% + 4rem);

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				bottom: 170px;
			}

			@media #{base.$desktop},
      #{base.$widescreen},
      #{base.$fullhd} {
				margin: -2rem;
			}

			svg {
				height: 100%;
				width: auto;

				path {
					//stroke: rgba($kyc-normal-blue, 0.5);
					stroke: var(--kyc-normal-blue-50);
					stroke-width: 0;
				}
			}

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				margin: 0;

				svg {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	#above-the-fold {
		.logo-outlined {
			opacity: 0.5;
		}
	}

	.narrow {
		h4,
		p {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}

			@media #{base.$mobile} {
				grid-column: 1/5;
			}

			@media #{base.$tablet} {
				grid-column: 2/8;
			}

			@media #{base.$desktop} {
				grid-column: 2/18;
			}

			@media #{base.$widescreen} {
				grid-column: 2/18;
			}

			@media #{base.$fullhd} {
				grid-column: 2/18;
			}

			line-height: 170%;
		}
	}

	#steps-teaser {
		grid-column: 1/24;

		.title {
			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				padding: 6rem 1rem 4rem 1rem;
				grid-column: 1/24;
			}

			@media #{base.$desktop} {
				padding: 8vh 0 4vh 0;
				grid-column: 3/21;
			}

			@media #{base.$widescreen},
      #{base.$fullhd} {
				padding: 10vh 0 6vh 0;
				grid-column: 3/21;
			}

			i,
			h2 {
				color: white;
			}
		}

		.the-steps {
			margin-bottom: -4.7rem;

			@media #{base.$desktop},
      #{base.$widescreen},
      #{base.$fullhd} {
				grid-column: 3/21;
			}

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				grid-column: 1/24;
				padding: 0 1rem 0 1rem;
			}

			Steps {
				width: calc(100% / 3);
				display: flex;

				.desktop-button {
					display: none;
				}

				@media #{base.$desktop},
        #{base.$widescreen},
        #{base.$fullhd} {
					float: left;
				}

				@media #{base.$smobile},
        #{base.$mobile},
        #{base.$tablet} {
					width: 100%;
				}
			}

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				flex-flow: column;

				.step {
					width: 100%;
					margin-bottom: var(--unit);

					.wrapper {
						height: auto;
					}
				}

				.step.dark {
				}
			}

			.step-head {
				height: 100%;
			}

			.step-task {
				display: flex;
				justify-content: flex-end;
				flex-flow: column;
				height: 100%;
			}
		}

		.step-task.dark,
		.step.blue {
			h2,
			h3,
			h4 {
				color: white !important;
			}
		}
	}

	#about-the-dao {
		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/3;
				padding: 2rem 1rem;
			}

			@media #{base.$mobile} {
				grid-column: 1/5;
				padding: 4rem 1rem;
			}

			@media #{base.$tablet} {
				grid-column: 2/8;
				padding: 4rem 0;
			}

			@media #{base.$desktop} {
				grid-column: 5/19;
			}

			@media #{base.$widescreen} {
				grid-column: 5/19;
			}

			@media #{base.$fullhd} {
				grid-column: 8/16;
			}

			h3 {
				margin-bottom: var(--unit);
			}
		}
	}

	#sign-up {
		.form-wrapper {
			position: relative;
			z-index: 10;

			.kyc-input {
				margin-bottom: 1rem;
			}
		}
	}
}
