@use "@Style/base/animations";

.single-select-list {
	display: flex;
	align-items: center;
	flex-flow: column;
	width: 100%;

	&.crowded {
		display: grid;
		grid: auto / auto auto;
		gap: 1rem;

		li {
			margin-bottom: 0;
		}
	}

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		cursor: pointer;
		margin-bottom: 0.5rem;

		padding: 0.225rem;
		margin-left: -0.225rem;
		border-radius: var(--border-radius-light);
		@extend .tr2;

		h4 {
			font-size: 20px;
			color: var(--kyc-dark-blue);
		}

		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			@extend .tr2;

			span {
				//border-radius: var(--border-radius-light);
				//font-size: 20px;
				//padding: 0.2rem;
				//margin: 0.2rem;
				visibility: hidden;
				opacity: 0;
				//display: none;
			}
		}
	}

	li:hover {
		background: white;

		& > div {
			span {
				//background: var(--kyc-normal-blue);
				display: inline-block;
				visibility: visible;
				opacity: 1;
			}
		}
	}

	li.selected {
		background: white;

		& > div {
			span {
				//background: var(--kyc-green-50);
				display: inline-block;
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
