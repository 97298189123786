@use "../../style/base";

#partners-page {
	#hello-hero {
		overflow: visible;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			margin-top: 0;
		}

		.hero-content-wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
			display: flex;
			flex-flow: column;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 1rem;
			}

			.headlines {
				display: flex;
				gap: var(--unit);
				justify-content: space-between;

				.text-wrapper {
					display: flex;
					flex-flow: column;
					gap: var(--unit);

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						max-width: 820px;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;
					}
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						flex-flow: column;

						.kyc-button {
							width: 100%;
							display: inline-flex;
							justify-content: space-between;
						}
					}
				}

				.image-wrapper {
					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				h1 {
					@extend .headline;
				}
			}
		}
	}

	#testimonial-swiper {
		//margin-top: var(--unit);
		margin-bottom: var(--unit);
		overflow: visible;

		.wrapper {
			grid-column: 1 / 23;
			padding: 0 var(--unit);

			.header {
				display: flex;
				gap: 1rem;

				h1 {
					line-height: 100%;
				}
			}

			.swiper {
				position: relative;
				padding-top: 2rem;

				& > ul {
					display: grid;
					gap: 2rem;

					right: 0;
					box-sizing: border-box;
					padding-top: 1rem;
					padding-bottom: 1rem;
					overflow: hidden;
					position: relative;

					//overflow: hidden;
					//position: absolute;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						grid-template-columns: repeat(1, 1fr);
					}

					@media #{base.$desktop} {
						grid-template-columns: repeat(2, 1fr);
					}

					@media #{base.$widescreen}, #{base.$fullhd} {
						grid-template-columns: repeat(3, 1fr);
					}

					& > li {
						background: white;
						padding: 2rem;
						height: fit-content;

						border-radius: var(--br12);
						border: 2px solid black;

						@media #{base.$smobile}, #{base.$mobile} {
							width: 100%;
						}

						@media #{base.$tablet}, #{base.$desktop} {
							//min-width: 500px;
						}

						@media #{base.$widescreen}, #{base.$fullhd} {
							//min-width: 600px;
						}

						span {
							font-family: var(--primary-font);
							@extend .text-sm;
							background: black;
							color: white;
							display: inline-flex;
							padding: 0 10px;
						}

						p {
							color: var(--kyc-dark);
							padding: 1rem 0;
						}

						ul {
							padding: 1rem 0;
							li {
								@extend p;
								list-style: initial;
								padding: 0;
							}
						}

						.footer {
							display: flex;
							justify-content: space-between;

							div {
								display: flex;
								flex-flow: column;
							}

							b,
							i {
								@extend .text-sm;
							}

							i {
								color: rgba(black, 0.8);
							}
						}
					}
				}
			}
		}
	}

	#contact-us {
		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/24;
			}
			@media #{base.$widescreen} {
				grid-column: 1/24;
			}
			@media #{base.$fullhd} {
				grid-column: 1/24;
			}

			display: flex;
			padding: 0 var(--unit) var(--unit) var(--unit);
			border-radius: var(--br24);
			border: 2px solid black;
			gap: var(--unit);
			flex-flow: column;

			.body {
				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					flex-flow: column;
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						flex-flow: column;
					}
				}
			}
		}
	}
}
