@use "../../style/base";

#hero {
	h2 {
		padding-top: var(--unit);
	}
}

.arrows {
	display: flex;
	align-items: center;
	cursor: pointer;

	i.material-icons {
		padding: 1rem;
		background: var(--kyc-cybergreen);
		color: black;
		border: 2px solid black;
		box-shadow: black 4px 4px 0px 0px;
		@extend .tr2;

		&:first-child {
			border-radius: 12px 0px 0 12px;
		}

		&:nth-child(2) {
			border-radius: 0px 12px 12px 0;
			margin-left: -2px;
		}

		&:hover {
			background: black;
			color: white;
		}

		&:active {
			background: var(--kyc-cyberpunk);
			color: black;
		}
	}
}

#grid-page {
	#hello-hero {
		margin-top: var(--unit);
		overflow: visible;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			margin-top: 0;
		}

		.hero-content-wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
			display: flex;
			flex-flow: column;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 1rem;
			}

			.headlines {
				display: flex;
				gap: var(--unit);
				justify-content: space-between;

				.text-wrapper {
					display: flex;
					flex-flow: column;
					gap: var(--unit);

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						max-width: 710px;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;
						align-items: center;

						h1,
						h2 {
							text-align: center;
						}
					}

					@media #{base.$tablet} {
						h1 {
							font-size: 6vw;
						}
					}
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile} {
						flex-flow: column;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						.kyc-button {
							display: inline-flex;
							//justify-content: space-between;

							justify-content: center;
							max-width: 330px;
							align-self: center;
						}
					}
				}

				.image-wrapper {
					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				h1 {
					@extend .headline;
				}
			}

			.usps {
				margin-top: var(--unit);
				margin-bottom: calc(var(--unit) * -2);
				padding: var(--unit);
				background: white;
				border: 2px solid black;
				border-radius: var(--br24);

				ul {
					display: flex;
					gap: 1rem;

					@media #{base.$smobile}, #{base.$mobile} {
						flex-flow: column;
					}

					li {
						display: flex;
						flex: 1;

						h4,
						p {
							color: black;
						}

						.circle {
							height: 100px;
							width: 100px;
							border-radius: 999rem;
							display: flex;
							align-items: center;
							justify-content: center;

							@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
								height: 70px;
								width: 70px;
							}

							i {
								color: white;
							}
						}

						.text {
							margin-left: 1rem;
							display: flex;
							flex-flow: column;
							flex: 1;
						}
					}

					li:first-child .circle {
						background-image: linear-gradient(#5f22fd, #2cf2ff);
					}

					li:nth-child(2) .circle {
						background-image: linear-gradient(#00ffb3, #2cf2ff);
					}

					li:nth-child(3) .circle {
						background-image: linear-gradient(#2cf0ff, #3d65f2);
					}
				}
			}
		}
	}

	#logos {
		//margin-top: var(--unit);

		.wrapper {
			display: flex;
			grid-column: 1/24;

			@media #{base.$smobile}, #{base.$mobile} {
				flex-flow: column;
				gap: 2rem;
			}

			.availabe,
			.soon {
				flex: 1;
				display: flex;
				flex-flow: column;
				align-items: center;

				span {
					font-size: 12px;
					font-family: var(--primary-font);
					margin: 1rem 0;
				}

				ul {
					display: flex;

					li {
						margin-right: var(--unit);

						svg {
							//filter: grayscale(0);
							filter: grayscale(100%);
						}
					}

					@media #{base.$smobile}, #{base.$mobile} {
						justify-content: space-between;

						svg {
							height: 2rem;
						}
					}
				}
			}

			.availabe {
			}
		}
	}

	.narrow {
		h4,
		p {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
			}
			@media #{base.$desktop} {
				grid-column: 2/18;
			}
			@media #{base.$widescreen} {
				grid-column: 2/18;
			}
			@media #{base.$fullhd} {
				grid-column: 2/18;
			}

			line-height: 170%;
		}
	}

	#compliance {
		.header {
			h1 {
				//background: var(--kyc-cybergreen);
				//color: white;
			}

			h4 {
				padding: var(--unit) 0 0;
				width: 50%;
				line-height: 170%;

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					width: 100%;
				}
			}
		}

		.wrapper {
			padding: 0 var(--unit);

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
		}

		ul {
			display: flex;
			padding: var(--unit) 0;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: column;
			}

			li {
				display: flex;
				flex-flow: column;
				flex: 1;

				h4 {
					padding-bottom: 2rem;
				}
			}
		}
	}

	#the-nft {
		overflow: hidden;

		.wrapper {
			padding: var(--unit);
			grid-column: 1/23;
			gap: var(--unit);
			display: flex;
			//background: var(--kyc-dark);

			.text-wrapper,
			.illustration {
				width: 50%;

				h1 {
				}

				h4 {
					margin-top: 2rem;
					line-height: 170%;
				}

				p {
					margin-top: var(--unit);
					color: black;

					ul {
						li {
							list-style: initial;
							color: black;
						}
					}
				}
			}

			.text-wrapper {
			}

			.illustration {
				img {
					width: 100%;
				}
			}
		}

		@media #{base.$smobile}, #{base.$mobile} {
			.wrapper {
				flex-flow: column;
			}

			.text-wrapper,
			.illustration {
				width: 100% !important;
				justify-content: center;
				text-align: center;
			}

			.illustration {
			}
		}
	}

	#the-sdk {
		overflow: hidden;

		.wrapper {
			padding: var(--unit);
			gap: var(--unit);
			display: flex;
			margin-top: calc(var(--unit) * -1);

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/23;
			}
			@media #{base.$widescreen} {
				grid-column: 1/23;
			}
			@media #{base.$fullhd} {
				grid-column: 1/23;
			}

			@media #{base.$smobile}, #{base.$mobile} {
				flex-flow: column;
				align-items: center;

				.illustration {
					img {
						width: 100%;
					}
				}
			}

			.text-wrapper,
			.illustration {
				width: 50%;

				@media #{base.$smobile}, #{base.$mobile} {
					width: 100%;
				}

				p {
					color: black;

					ul {
						li {
							list-style: initial;
							color: black;
						}
					}
				}
			}

			.headline-wrapper {
				h4 {
					margin-top: 2rem;
					line-height: 170%;
				}
			}

			.text-wrapper {
				display: flex;
				flex-flow: column;
				gap: 2rem;
			}

			.swiper-part,
			.text-wrapper {
				flex: 1;
			}

			.swiper-part {
				display: flex;

				.card-controller,
				.illustration {
					flex: 1;
				}
			}

			.card-controller {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				justify-content: center;

				ul {
					li {
						margin-bottom: 0.5rem;
						display: inline-flex;
						align-items: center;
						border: 2px solid transparent;
						border-radius: 12px;
						padding-right: 1rem;
						cursor: pointer;

						i {
							padding: 0.5rem;
							display: flex;
							height: 100%;
							background: black;
							color: white;
							border-radius: 9px 0 0 9px;
							visibility: hidden;
						}

						span {
							display: flex;
							margin-left: 10px;
							@extend .text-sm;
						}
					}

					li.selected {
						border: 2px solid black;

						span {
							height: 100%;
						}

						i {
							visibility: visible;
						}
					}

					@media #{base.$smobile}, #{base.$mobile} {
						display: flex;
						flex-flow: row;

						width: 100%;
						flex-wrap: wrap;
						overflow: hidden;

						li {
							i {
								display: none;
							}
						}
					}
				}
			}

			.paragraph-wrapper {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				justify-content: center;
				height: 100%;
			}

			.illustration {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: var(--unit);

				img {
					height: 100%;
					max-height: 500px;
				}
			}

			@media #{base.$smobile}, #{base.$mobile} {
				.headline-wrapper,
				.text-wrapper {
					text-align: center;
				}

				.card-controller {
					align-items: center;

					ul {
						justify-content: center;
					}
				}

				.swiper-part {
					flex-flow: column;
				}
			}
		}
	}

	#integration {
		.wrapper {
			padding: 0 var(--unit);
			grid-column: 1/23;
			gap: var(--unit);
			display: flex;
			border-radius: var(--br24);

			background: white;
			border: 2px solid black;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: column;

				.code,
				.process {
					width: 100%;
				}
			}

			.code,
			.process {
				@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
					width: 50%;
				}
			}

			.step {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;

				span {
					width: 2rem;
					height: 2rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--kyc-cyberpunk);
					border-radius: 999rem;
				}

				p {
					padding: 1rem;
				}
			}

			.code {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				padding-bottom: var(--unit);

				p {
					margin-top: var(--unit);
					color: white;
				}
			}

			.code-wrapper {
				background: black;
				border: 2px solid black;
				box-shadow: black 4px 4px 0px 0px;
				border-radius: var(--br12);

				.header {
					background: white;
					display: flex;
					align-items: center;
					border-radius: var(--br12) var(--br12) 0 0;
				}

				i {
					width: 4rem;
					height: 4rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--kyc-cybergreen);
					color: black;
					border-right: 2px solid black;
					border-radius: var(--br12) 0px 0 0;
				}

				h4 {
					padding-left: 1rem;
				}

				.code-content {
					padding: 2rem;
				}

				code {
					@extend .text-sm;
					color: white;
				}
			}

			.button-wrapper {
				display: flex;

				.kyc-button {
					margin-right: 3rem;
				}

				@media #{base.$smobile}, #{base.$mobile} {
					flex-flow: column;
					justify-content: center;

					.kyc-button {
						margin-right: 0;
						margin-bottom: 1rem;

						display: inline-flex;
						justify-content: center;
						max-width: 330px;
						align-self: center;
					}
				}
			}

			.process {
				padding-top: calc(var(--unit) * 2);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					padding-top: 0;
				}

				p {
					color: black;
				}
			}
		}
	}

	#usecase-swiper {
		flex-flow: column;
		margin-bottom: var(--unit);
		//margin-bottom: calc(var(--unit) * 3);

		.wrapper {
			display: flex;
			flex-flow: column;
			grid-column: 1 / 12;
			padding: var(--unit);

			.header {
				display: flex;
				gap: 1rem;

				@media #{base.$smobile}, #{base.$mobile} {
					flex-flow: column;
					gap: 1px;
					margin-bottom: 1rem;

					h1 {
						line-height: 100%;
						width: max-content;
					}
				}
			}

			h4 {
				margin-top: 2rem;
				line-height: 170%;

				@media #{base.$smobile}, #{base.$mobile} {
					margin-top: 0;
				}
			}
		}

		/* LISTING UNIT DEFINED HERE */

		--list-size: calc((var(--unit) * 4) - 3px);

		.listing {
			grid-column: 1 / 24;
			padding: 0 var(--unit) 0 var(--unit);

			ul {
				display: flex;
				gap: var(--unit);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					flex-flow: column;
				}

				li {
					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						width: var(--list-size);
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;

						h4 {
						}
					}

					.header {
						display: flex;
						box-shadow: black 4px 4px 0px 0px;
						border-radius: var(--br12);
						overflow: hidden;
						border: 2px solid black;

						.image-wrapper {
							background: black;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;
							width: min-content;
							padding: 1rem;
						}

						.body {
							display: flex;
							height: 100%;
						}

						.title-wrapper {
							background: white;
							flex: 1;
							position: relative;

							h2,
							h3,
							h4 {
								padding: 1rem;
							}

							span {
								display: flex;
								width: 24px;
								height: 24px;
								justify-content: center;
								align-items: center;
								position: absolute;
								right: 0;
								top: 0;
								font-family: gilroyBold;
								background: var(--kyc-cyberpunk);
							}
						}
					}

					.footer {
						margin-top: 1rem;

						.examples {
							margin-bottom: 1rem;

							background: var(--kyc-cyberpunk);
							display: inline-flex;
						}

						.button {
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-bottom: 2px solid black;
							padding: 6px 0;
							position: relative;
							cursor: pointer;

							&:before {
								display: block;
								content: "";
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								width: 0;
								background-color: var(--kyc-cybergreen);
								z-index: -1;
								transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);
							}

							span {
								color: black;
							}

							&:hover {
								&:before {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	#benefits-grid {
		.title-wrapper {
			grid-column: 1/21;
			margin-bottom: var(--unit);
		}

		ul {
			grid-column: 1/21;
			display: grid;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				grid-template-columns: repeat(1, 1fr);
			}

			@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
				grid-template-columns: repeat(3, 1fr);
			}

			li {
				display: flex;
				flex-flow: column;
				gap: 1rem;

				.image-wrapper {
					height: 124px;
					display: flex;
					align-items: center;

					img {
						width: 100px;
					}
				}
			}
		}
	}

	#about-the-dao {
		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/3;
				padding: 2rem 1rem;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
				padding: 4rem 1rem;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
				padding: 4rem 0;
			}
			@media #{base.$desktop} {
				grid-column: 5/19;
			}
			@media #{base.$widescreen} {
				grid-column: 5/19;
			}
			@media #{base.$fullhd} {
				grid-column: 8/16;
			}

			h3 {
				margin-bottom: var(--unit);
			}
		}
	}

	#sign-up {
		.form-wrapper {
			position: relative;
			z-index: 10;

			.kyc-input {
				margin-bottom: 1rem;
			}
		}
	}
}

/* Animations */

#hero.hero-loaded {
	.headline-wrapper {
		h1 {
			top: 0;
			transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1),
				opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
		}
	}
}
