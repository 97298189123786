@use "../../style/base";

#hero {
	h2 {
		padding-top: var(--unit);
	}
}

.arrows {
	display: flex;
	align-items: center;
	cursor: pointer;

	i.material-icons {
		padding: 1rem;
		background: var(--kyc-cybergreen);
		color: black;
		border: 2px solid black;
		box-shadow: black 4px 4px 0px 0px;
		@extend .tr2;

		@media #{base.$smobile}, #{base.$mobile} {
			padding: 0.5rem;
		}

		&:first-child {
			border-radius: 12px 0px 0 12px;
		}

		&:nth-child(2) {
			border-radius: 0px 12px 12px 0;
			margin-left: -2px;
		}

		&:hover {
			background: black;
			color: white;
		}

		&:active {
			background: var(--kyc-cyberpunk);
			color: black;
		}
	}
}

.initial-character {
	margin-left: 1%;
	margin-right: 1%;

	svg {
		margin-bottom: 0.5vw;
	}
}

.product-properties-listing {
	display: flex;
	flex-flow: column;
	gap: 2rem;
	justify-content: center;
	height: 100%;

	li {
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
		margin-left: -20px;

		@media #{base.$smobile}, #{base.$mobile} {
			margin-left: 0;
		}

		span {
			font-family: var(--display-font) !important;
			//color: var(--kyc-normal-blue);
			color: black;
			@extend .xl-text;
			margin-right: 6px;
		}
	}
}

@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
	section#logos {
		margin-bottom: 3rem;
	}

	section#the-sdk {
		margin-bottom: 3rem;
	}

	section#the-nft {
		margin-bottom: 3rem;
	}

	section#integration {
		margin-bottom: 3rem;
	}

	section#compliance {
		margin-bottom: 3rem;
		padding-top: 0;
	}

	section#usecase-swiper {
		//margin-bottom: 3rem;
	}

	section#other-contact-channels {
		margin-top: 4rem;
	}

	#other-contact-channels .channels.support {
		margin-top: 3rem;
	}
}

#home-page {
	#hello-hero {
		overflow: visible;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			max-width: 100%;
			margin-top: 0;

			.container {
				border-radius: 0;
			}
		}

		.hero-content-wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
			display: flex;
			flex-flow: column;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 1rem;
			}

			.headlines {
				display: flex;
				gap: var(--unit);
				justify-content: space-between;

				.text-wrapper {
					display: flex;
					flex-flow: column;
					gap: var(--unit);

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						width: 100%;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;

						.headline-wrapper {
							width: 100%;
						}

						h2 {
							@media #{base.$smobile}, #{base.$mobile} {
								margin: 2rem 0;
							}
						}
					}

					@media #{base.$tablet} {
						h1 {
							//font-size: 6vw;
						}
					}
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile} {
						flex-flow: column;

						.kyc-button {
							width: 100%;
						}
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						.kyc-button {
							display: inline-flex;
						}
					}
				}

				.image-wrapper {
					width: 100%;
					display: flex;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				h1 {
					@extend .headline;
				}
			}

			.usps {
				margin-top: var(--unit);
				margin-bottom: calc(var(--unit) * -2);
				padding: var(--unit);
				background: white;
				border: 2px solid black;
				border-radius: var(--br24);

				display: flex;
				gap: var(--unit);
				flex-flow: column;

				/*
        @media #{base.$smobile}, #{base.$mobile} {
          margin-left: calc(var(--unit) * -1);
          margin-right: calc(var(--unit) * -1);
          border-radius: 0;
          border-top: 2px solid black;
          border-left: none;
          border-right: none;
          padding: 1rem;
        }
        */

				ul {
					display: grid;
					gap: 1rem;

					@media #{base.$smobile}, #{base.$mobile} {
						grid-template-columns: repeat(1, 1fr);
						gap: 3rem;
						padding-top: 2rem;
						padding-bottom: 2rem;
					}

					@media #{base.$tablet}, #{base.$desktop} {
						grid-template-columns: repeat(2, 1fr);
					}

					@media #{base.$widescreen}, #{base.$fullhd} {
						grid-template-columns: repeat(3, 1fr);
					}

					li {
						display: flex;
						flex: 1;

						h4,
						p {
							color: black;
						}

						.circle {
							height: 6vw;
							width: 6vw;
							border-radius: 999rem;
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								width: 100%;
							}

							@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
								height: 70px;
								width: 70px;
							}

							i {
								color: white;
							}
						}

						.text {
							margin-left: 1rem;
							display: flex;
							flex-flow: column;
							flex: 1;
						}
					}
				}
			}
		}
	}

	#logos {
		.wrapper {
			display: flex;
			grid-column: 1/24;

			@media #{base.$smobile}, #{base.$mobile} {
				flex-flow: column;
				gap: 2rem;
			}

			.availabe,
			.soon {
				flex: 1;
				display: flex;
				flex-flow: column;
				align-items: center;

				span {
					font-size: 12px;
					font-family: var(--primary-font);
					margin: 1rem 0;
				}

				ul {
					display: flex;
					gap: var(--unit);

					li {
						position: relative;
						display: flex;
						justify-content: center;

						@media #{base.$tablet}, #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd}, #{base.$superlarge} {
							width: 4vw;
						}

						svg {
							filter: grayscale(100%);
						}

						img {
							filter: grayscale(100%);
						}
					}

					@media #{base.$superlarge} {
						img {
							height: var(--unit);
						}
					}

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						img {
							filter: grayscale(100%);
							height: 3.5vw;
						}
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						justify-content: space-between;

						svg {
							height: 42px;
						}

						img {
							height: 42px;
						}
					}
				}
			}

			.availabe {
			}
		}
	}

	.narrow {
		h4,
		p {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
			}
			@media #{base.$desktop} {
				grid-column: 2/18;
			}
			@media #{base.$widescreen} {
				grid-column: 2/18;
			}
			@media #{base.$fullhd} {
				grid-column: 2/18;
			}

			line-height: 170%;
		}
	}

	#how-does-it-work {
		.wrapper {
			padding: 0 var(--unit);

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
		}
	}

	#compliance {
		@media #{base.$smobile}, #{base.$mobile} {
			padding-top: var(--unit);
		}

		.wrapper {
			padding: 0 var(--unit);

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/24;
			}
			@media #{base.$widescreen} {
				grid-column: 1/24;
			}
			@media #{base.$fullhd} {
				grid-column: 1/24;
			}
		}

		h4 {
			line-height: 170%;
		}

		.header {
			h4 {
				padding: var(--unit) 0 0;
				width: 60%;

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					width: 100%;
				}
			}
		}

		h4 {
			padding: var(--unit) 0;
		}

		ul {
			display: flex;
			padding: var(--unit) 0;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: column;
			}

			li {
				display: flex;
				flex-flow: column;
				flex: 1;

				h4 {
					padding-bottom: 2rem;
				}
			}
		}
	}

	#the-nft {
		border-radius: 0;
		overflow: visible;

		.wrapper {
			padding: var(--unit);
			gap: var(--unit);
			display: flex;

			@media #{base.$smobile}, #{base.$mobile} {
				padding: 0;
			}

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/23;
			}
			@media #{base.$widescreen} {
				grid-column: 1/23;
			}
			@media #{base.$fullhd} {
				grid-column: 1/23;
			}

			.headline-wrapper {
				margin-bottom: 1rem;
			}

			.text-wrapper,
			.illustration {
				width: 50%;
				height: 100%;
				display: flex;
				flex-flow: column;

				@media #{base.$smobile}, #{base.$mobile} {
					width: 100%;
				}

				h4 {
					line-height: 170%;
					display: flex;
					height: 100%;
					align-items: center;
				}

				p {
					margin-top: var(--unit);
					color: black;

					ul {
						li {
							list-style: initial;
							color: black;
						}
					}
				}
			}

			.text-wrapper {
				ul {
					li {
						list-style: initial;
					}
				}
			}

			.illustration {
				img {
					width: 100%;
				}
			}
		}

		@media #{base.$smobile}, #{base.$mobile} {
			.wrapper {
				flex-flow: column;
			}
		}
	}

	#the-sdk {
		overflow: hidden;

		.wrapper {
			padding: var(--unit);
			gap: var(--unit);
			display: flex;
			margin-top: calc(var(--unit) * -1);
			margin-bottom: calc(var(--unit) * -2);

			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/23;
			}
			@media #{base.$widescreen} {
				grid-column: 1/23;
			}
			@media #{base.$fullhd} {
				grid-column: 1/23;
			}

			@media #{base.$smobile}, #{base.$mobile} {
				flex-flow: column;

				.illustration {
					img {
						//width: 100%;
					}
				}
			}

			@media #{base.$tablet} {
				flex-flow: column;
			}

			.text-wrapper,
			.illustration {
				width: 50%;

				@media #{base.$smobile}, #{base.$mobile} {
					width: 100%;
				}

				p {
					color: black;

					ul {
						li {
							list-style: initial;
							color: black;
						}
					}
				}
			}

			.headline-wrapper {
				h4 {
					margin-top: 2rem;
					line-height: 170%;
				}
			}

			.text-wrapper {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				flex: 1;
			}

			.swiper-part,
			.text-wrapper {
			}

			.swiper-part {
				display: flex;

				@media #{base.$fullhd} {
					flex: 1;
				}

				@media #{base.$widescreen}, #{base.$desktop}, #{base.$tablet} {
					flex: 2;
				}

				.card-controller,
				.illustration {
					flex: 1;
				}
			}

			.card-controller {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				justify-content: center;

				ul {
					@media #{base.$desktop}, #{base.$tablet} {
						display: flex;
						flex-flow: column;
					}

					li {
						margin-bottom: 0.5rem;
						display: inline-flex;

						border: 2px solid transparent;
						border-radius: 12px;
						padding-right: 1rem;
						cursor: pointer;
						width: max-content;
						overflow: hidden;
						@extend .tr2;

						@media #{base.$smobile}, #{base.$mobile} {
							padding: 0.5rem;
							border-radius: 24px;
							border: 2px solid black;
						}

						i {
							margin-top: -1px;
							margin-bottom: -1px;
							padding: 0.65vw;
							font-size: 1.25vw;
							display: flex;
							height: 100%;
							background: black;
							color: white;
							border-radius: 9px 0 0 9px;
							visibility: hidden;

							@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
								font-size: 24px;
							}
						}

						span {
							display: flex;
							margin-left: 10px;
							align-self: center;
							@extend .md-text;
							@extend .tr2;
						}
					}

					li:hover {
						background: black;

						span {
							color: white;
						}
					}

					li.selected {
						border: 2px solid black;

						span {
							//height: 100%;
						}

						i {
							visibility: visible;
						}
					}

					@media #{base.$smobile}, #{base.$mobile} {
						display: flex;
						flex-flow: row;

						width: 100%;
						flex-wrap: wrap;
						overflow: hidden;
						gap: 0.5rem;

						li {
							i {
								display: none;
							}
						}
					}
				}
			}

			.cards-wrapper {
				display: flex;
				padding-top: var(--unit);

				.illustration {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-top: var(--unit);

					@media #{base.$smobile}, #{base.$mobile} {
						height: 580px;
					}

					img {
						height: 100%;
						max-height: 500px;
					}
				}
			}

			/* HERE */
			.product-properties-listing {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				justify-content: center;
				height: 100%;

				li {
					margin-bottom: 0.5rem;
					display: flex;
					align-items: center;
					margin-left: -20px;

					@media #{base.$smobile}, #{base.$mobile} {
						margin-left: 0;
					}

					span {
						font-family: var(--display-font) !important;
						//color: var(--kyc-cybergreen);
						color: black;
						@extend .xl-text;
						margin-right: 6px;
					}
				}
			}

			@media #{base.$smobile}, #{base.$mobile} {
				.headline-wrapper,
				.text-wrapper {
				}

				.card-controller {
					ul {
					}
				}

				.swiper-part {
					flex-flow: column;
				}
			}
		}
	}

	#integration {
		.wrapper {
			padding: 0 var(--unit);
			grid-column: 1/23;
			gap: var(--unit);
			display: flex;
			border-radius: var(--br24);

			background: white;
			border: 2px solid black;

			@media #{base.$widescreen}, #{base.$fullhd} {
				overflow: hidden;
			}

			@media #{base.$smobile}, #{base.$mobile} {
				grid-column: 1/5;
				border: none !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: column;

				.code,
				.process {
					width: 100%;
				}
			}

			.code,
			.process {
				@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
					width: 50%;
				}
			}

			.process {
				.step {
					display: flex;
					flex-flow: row;
					justify-content: flex-start;
					align-items: center;
					top: 0;
					margin-bottom: 1rem;

					span {
						@extend .text-sm;

						@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
							width: 2rem;
							height: 2rem;
						}

						@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
							width: 2vw;
							height: 2vw;
						}

						display: flex;
						align-items: center;
						justify-content: center;
						background: var(--kyc-cyberpunk);
						border-radius: 999rem;
					}

					p {
						padding: 1rem;
					}
				}
			}

			.code-content {
				padding: 2rem;

				.code-example {
					.step-instruction {
						display: flex;
						align-items: center;
						margin-bottom: 1rem;

						p {
							margin: 0;
						}

						span {
							margin-right: 1rem;
							width: 2rem;
							height: 2rem;
							display: flex;
							align-items: center;
							justify-content: center;
							background: var(--kyc-cyberpunk);
							border-radius: 999rem;
						}
					}

					pre {
						background: none !important;
						padding: 0 !important;

						.linenumber {
							min-width: 1.2em !important;
						}
					}
				}
			}

			.code-example.large {
				overflow-y: scroll;
			}

			.code {
				display: flex;
				flex-flow: column;
				gap: 2rem;
				padding-bottom: var(--unit);

				p {
					margin-top: var(--unit);
					color: white;
				}
			}

			.code-wrapper {
				background: black;
				border: 2px solid black;
				box-shadow: black 4px 4px 0px 0px;
				border-radius: var(--br12);

				.header {
					background: white;
					display: flex;
					align-items: center;
					border-radius: var(--br12) var(--br12) 0 0;
				}

				i {
					width: 4rem;
					height: 4rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--kyc-cybergreen);
					color: black;
					border-right: 2px solid black;
					border-radius: var(--br12) 0px 0 0;
				}

				h4 {
					padding-left: 1rem;
				}

				code {
					@extend .text-sm;
					color: white;
				}
			}

			.button-wrapper {
				display: flex;

				.kyc-button {
					margin-right: 3rem;
				}

				@media #{base.$smobile}, #{base.$mobile} {
					flex-flow: column;
					justify-content: center;

					.kyc-button {
						margin-right: 0;
						margin-bottom: 1rem;

						display: inline-flex;
						justify-content: center;
						max-width: 330px;
						align-self: center;
					}
				}

				@media #{base.$tablet}, #{base.$desktop} {
					width: 200%;
				}
			}

			.process {
				padding-top: calc(var(--unit) * 2);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					padding-top: 0;
				}

				a.step {
					align-items: center;
					color: black;

					span {
						color: black;
					}

					p {
						color: black;
					}

					&:hover {
						text-decoration: none;

						span {
							color: black;
						}

						p {
							color: var(--kyc-normal-blue);
							text-decoration: underline;
						}

						.material-icons {
							color: var(--kyc-normal-blue);
						}
					}

					:visited {
						color: black;
					}
				}
			}
		}
	}

	#usecase-swiper {
		flex-flow: column;
		margin-bottom: var(--unit);

		.wrapper {
			display: flex;
			flex-flow: column;
			grid-column: 1 / 12;
			padding: var(--unit);

			.header {
				display: flex;
				gap: 1rem;

				@media #{base.$smobile}, #{base.$mobile} {
					flex-flow: column;
					gap: 1px;
					margin-bottom: 1rem;

					h1 {
						width: max-content;
					}
				}
			}

			h4 {
				margin-top: 2rem;
				line-height: 170%;

				@media #{base.$smobile}, #{base.$mobile} {
					margin-top: 0;
				}
			}
		}

		/* LISTING UNIT DEFINED HERE */

		--list-size: calc((var(--unit) * 4) - 3px);

		.listing {
			grid-column: 1 / 24;
			padding: 0 var(--unit) 0 var(--unit);

			ul {
				display: flex;
				gap: var(--unit);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					flex-flow: column;
				}

				li {
					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						width: var(--list-size);
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;
						margin-bottom: 0;

						h4 {
						}
					}

					.header {
						display: flex;
						box-shadow: black 4px 4px 0px 0px;
						border-radius: var(--br12);
						overflow: hidden;
						border: 2px solid black;

						.image-wrapper {
							background: black;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;
							width: min-content;
							padding: 1rem;
						}

						.title-wrapper {
							background: white;
							flex: 1;
							position: relative;

							h2,
							h3,
							h4 {
								padding-top: 2rem;
								padding-left: 1rem;
							}

							h5 {
								padding-top: 2rem;
								padding-left: 1rem;
								font-size: 0.8rem;
							}

							span {
								display: flex;
								width: 24px;
								height: 24px;
								justify-content: center;
								align-items: center;
								position: absolute;
								right: 0;
								top: 0;
								font-family: gilroyBold;
								background: var(--kyc-cyberpunk);
							}
						}
					}

					.body {
						margin-top: 1rem;
					}

					.footer {
						margin-top: 1rem;

						.examples {
							margin-bottom: 1rem;

							background: var(--kyc-cyberpunk);
							display: inline-flex;
						}

						.button {
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-bottom: 2px solid black;
							padding: 6px 0;
							position: relative;
							cursor: pointer;

							&:before {
								display: block;
								content: "";
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								width: 0;
								background-color: var(--kyc-cybergreen);
								z-index: -1;
								transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);
							}

							span {
								color: black;
							}

							&:hover {
								&:before {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	#benefits-grid {
		.container {
			border-radius: 0 !important;
		}

		.title-wrapper {
			grid-column: 1/21;
			margin-bottom: var(--unit);
		}

		ul {
			grid-column: 1/21;
			display: grid;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile} {
				grid-template-columns: repeat(1, 1fr);
			}

			@media #{base.$tablet} {
				grid-template-columns: repeat(2, 1fr);
			}

			@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
				grid-template-columns: repeat(3, 1fr);
			}

			li {
				display: flex;
				flex-flow: column;
				gap: 1rem;

				.image-wrapper {
					height: 124px;
					display: flex;
					align-items: center;

					img {
						width: 100px;
					}
				}
			}

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				li {
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	#about-the-dao {
		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/3;
				padding: 2rem 1rem;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
				padding: 4rem 1rem;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
				padding: 4rem 0;
			}
			@media #{base.$desktop} {
				grid-column: 5/19;
			}
			@media #{base.$widescreen} {
				grid-column: 5/19;
			}
			@media #{base.$fullhd} {
				grid-column: 8/16;
			}

			h3 {
				margin-bottom: var(--unit);
			}
		}
	}

	#sign-up {
		.form-wrapper {
			position: relative;
			z-index: 10;

			.kyc-input {
				margin-bottom: 1rem;
			}
		}
	}

	/*
  #get-in-touch {
    margin-bottom: calc(var(--unit) / 2);
    padding: 0;
    width: 100%;

    .wrapper {

      @media #{base.$smobile} {
        grid-column: 1/4;
      }
      @media #{base.$mobile} {
        grid-column: 1/5;
      }
      @media #{base.$tablet} {
        grid-column: 1/10;
      }
      @media #{base.$desktop} {
        grid-column: 1/24;
      }
      @media #{base.$widescreen} {
        grid-column: 1/24;
      }
      @media #{base.$fullhd} {
        grid-column: 1/24;
      }

      display: flex;
      justify-content: space-between;
      border-radius: var(--br24);
      border: 2px solid black;
      overflow: hidden;
      padding: 0 var(--unit);
      flex-flow: row;
      flex: 1;

      &>div{
        width: 100%;
      }

      @media #{base.$smobile}, #{base.$mobile}, #{base.$tablet}, #{base.$desktop} {
        flex-flow: column;
      }

      .body {
        padding-bottom: var(--unit);

        p {
          margin: var(--unit) 0;
        }

        .button-wrapper {
          display: flex;
          gap: 2rem;

          @media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
            flex-flow: column;
            margin-bottom: var(--unit);
          }

          .kyc-button.primary {
            background: var(--kyc-cybergreen);

            i,
            span {
              color: black;
            }

            &:hover {
              background: var(--kyc-normal-blue);

              i,
              span {
                color: white;
              }
            }
          }
        }
      }

      #contact{

      }

      #join-us{
        border-left: 2px solid black;

        body{
          padding-left: var(--unit);
        }

      }

    }
  }

   */

	#other-contact-channels {
		margin-bottom: var(--unit);

		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/24;
			}
			@media #{base.$widescreen} {
				grid-column: 1/24;
			}
			@media #{base.$fullhd} {
				grid-column: 1/24;
			}

			display: flex;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet}, #{base.$desktop} {
				flex-flow: column;
			}
		}

		.channels {
			padding: 0 var(--unit) var(--unit) var(--unit);
			flex: 1;

			.body {
				p {
					margin: var(--unit) 0;
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						flex-flow: column;
					}

					.kyc-button.primary {
						background: var(--kyc-cybergreen);

						i,
						span {
							color: black;
						}

						&:hover {
							background: var(--kyc-normal-blue);

							i,
							span {
								color: white;
							}
						}
					}
				}
			}
		}

		.channels.get-in-touch {
			border-radius: var(--br24);
			border: 2px solid black;
			overflow: hidden;

			@media #{base.$smobile}, #{base.$mobile} {
				.button-wrapper {
					padding-bottom: 1rem;
					gap: 1rem;
				}
			}
		}

		.channels.support {
			border-radius: var(--br24);
			background: var(--bg-gray);
			overflow: hidden;
		}
	}
}

/* Animations */

#hero.hero-loaded {
	.headline-wrapper {
		h1 {
			top: 0;
			transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1),
				opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
		}
	}
}
