@use "src/style/base/global-constants" as gc;

@each $width, $column-count in gc.$grid-columns {
	@media screen and (max-width: $width) {
		.grid {
			grid: 1fr / repeat($column-count, 1fr);
			position: relative;
			z-index: 0;
		}
	}
}

/* N is the count of the repaeting, S is the value */
@function column-looper($n, $s) {
	$column: "";
	@for $i from 1 through $n {
		$column: $column + $s + " ";
	}
	@return str_slice(unquote($column), 0, str_length($column)-1);
}

@each $width, $column-count in gc.$grid-columns {
	@media screen and (max-width: $width) {
		.grid {
			grid: 1fr / repeat($column-count, 1fr);
			position: relative;
			z-index: 0;
		}
	}
}

/* N is the count of the repaeting, S is the value */
@function column-looper($n, $s) {
	$column: "";
	@for $i from 1 through $n {
		$column: $column + $s + " ";
	}
	@return str_slice(unquote($column), 0, str_length($column)-1);
}

.container-wrapper {
	position: relative;

	.near-optiongrid-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: visible;
		z-index: 0;

		.grid {
			display: grid;
			position: relative;
			height: 100%;
			width: 100%;

			@media #{gc.$smobile}, #{gc.$mobile}, #{gc.$tablet} {
				padding: 0 1rem;
			}

			span {
				width: 1px;
				background: var(--border);
			}

			span:first-child {
				background: transparent;
			}
		}
	}

	.container {
		position: relative;
		display: grid;
	}
}

#background-grid,
.container-wrapper {
	position: relative;

	.grid-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: visible;
		z-index: 0;

		.grid {
			display: grid;
			position: relative;
			height: 100%;
			width: 100%;

			@media #{gc.$smobile}, #{gc.$mobile}, #{gc.$tablet} {
				padding: 0 1rem;
			}

			span {
				width: 1px;
				background: var(--border);
			}

			span:first-child {
				background: transparent;
			}
		}
	}

	.container {
		position: relative;
		display: grid;
	}
}

#background-grid,
.container {
	&.no-grid {
		display: flex;
	}

	@media #{gc.$smobile} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			gc.$small-mobile-column-count,
			gc.$small-mobile-column-width
		);
	}

	@media #{gc.$mobile} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			gc.$mobile-column-count,
			gc.$mobile-column-width
		);
	}

	@media #{gc.$tablet} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			gc.$table-column-count,
			gc.$table-column-width
		);
	}

	@media #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
		&.narrow {
			max-width: calc(100% - (#{gc.$grid-col-width * 6}));
			grid-template-columns: column-looper(18, gc.$narrow-col-width);
			/* Heres should be = 18 cols --> 24 - 6 gutter) */
		}

		&.normal {
			max-width: calc(100% - (#{gc.$grid-col-width * 4}));
			grid-template-columns: column-looper(20, gc.$normal-col-width);
			/* Heres should be = 20 cols --> 24 - 4 gutter) */
		}

		&.wide {
			max-width: calc(100% - (#{gc.$grid-col-width * 2}));
			grid-template-columns: column-looper(22, gc.$full-hd-col-width);
			/* Heres should be = 22 cols --> 24 - 2 gutter) */
		}

		&.full {
			max-width: 100%;
			grid-template-columns: column-looper(24, gc.$full-hd-col-width);
			/* Heres should be = 24 */
		}
	}
}

#background-grid {
	position: fixed;
	z-index: 0;
	height: 100vh;
	left: 0;
	right: 0;

	.grid {
		span {
			background: var(--border);
			//background: red !important;
		}
	}
}

.container {
	.grid {
		span {
			background: none !important;
		}
	}
}

///////////////

/* GRID */

.one-of-three {
	@media #{gc.$smobile} {
		grid-column: 0/2;
	}
	@media #{gc.$mobile} {
		grid-column: 1/5;
	}
	@media #{gc.$tablet} {
		grid-column: 1/4;
	}
	@media #{gc.$desktop} {
		grid-column: 1/10;
	}
	@media #{gc.$widescreen} {
		grid-column: 1/6;
	}
	@media #{gc.$fullhd} {
		grid-column: 1/6;
	}
}

.two-of-three {
	@media #{gc.$smobile} {
		grid-column: 0/2;
	}
	@media #{gc.$mobile} {
		grid-column: 1/5;
	}
	@media #{gc.$tablet} {
		grid-column: 1/4;
	}
	@media #{gc.$desktop} {
		grid-column: 1/10;
	}
	@media #{gc.$widescreen} {
		grid-column: 6/12;
	}
	@media #{gc.$fullhd} {
		grid-column: 6/12;
	}
}

.three-of-three {
	@media #{gc.$smobile} {
		grid-column: 0/2;
	}
	@media #{gc.$mobile} {
		grid-column: 1/5;
	}
	@media #{gc.$tablet} {
		grid-column: 1/4;
	}
	@media #{gc.$desktop} {
		grid-column: 5/10;
	}
	@media #{gc.$widescreen} {
		grid-column: 12/18;
	}
	@media #{gc.$fullhd} {
		grid-column: 12/18;
	}
}

.first-half {
	@media #{gc.$smobile} {
		grid-column: 0/2;
	}
	@media #{gc.$mobile} {
		grid-column: 1/4;
	}
	@media #{gc.$tablet} {
		grid-column: 1/4;
	}
	@media #{gc.$desktop} {
		grid-column: 1/6;
	}
	@media #{gc.$widescreen} {
		grid-column: 1/12;
	}
	@media #{gc.$fullhd} {
		grid-column: 1/12;
	}
}

.second-half {
	@media #{gc.$smobile} {
		grid-column: 0/2;
	}
	@media #{gc.$mobile} {
		grid-column: 1/4;
	}
	@media #{gc.$tablet} {
		grid-column: 1/4;
	}
	@media #{gc.$desktop} {
		grid-column: 6/12;
	}
	@media #{gc.$widescreen} {
		grid-column: 12/24;
	}
	@media #{gc.$fullhd} {
		grid-column: 12/24;
	}
}
