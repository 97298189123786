@use "@Style/base.scss";
@use "@Components/button/button.component";

.info-line {
	background: var(--bg-gray);
	//border: 2px solid black;
	padding: 1rem;
	border-radius: var(--br12);
	width: calc(100% - (var(--unit) * 2));
	display: flex;
	align-items: center;

	max-width: fit-content;
	justify-content: center;
	margin: var(--unit) auto 0;

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		margin: 1rem 1rem 0 1rem;
	}

	i {
		color: var(--kyc-normal-blue);
		margin-right: 1rem;
	}

	p,
	h4 {
		color: black;

		a.link-button {
			&::after {
				bottom: -9px;
				height: 1px;
			}
			&:hover {
				&::after {
					height: 9px;
					bottom: -9px;
				}
			}
		}
	}
}

#app-page {
	#join-us-header {
		overflow: visible;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			margin-top: 0;
		}

		.hero-content-wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
			display: flex;
			flex-flow: column;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 1rem;
			}

			.headlines {
				display: flex;
				gap: var(--unit);
				justify-content: space-between;

				.text-wrapper {
					display: flex;
					flex-flow: column;
					gap: var(--unit);

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
						width: 100%;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;
						align-items: center;

						.headline-wrapper {
							width: 100%;
						}
					}

					h2 {
						@media #{base.$smobile}, #{base.$mobile} {
							margin: 2rem 0;
						}
					}
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile} {
						flex-flow: column;
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;

						.kyc-button {
							display: inline-flex;
							//justify-content: center;
							//max-width: 330px;
							//align-self: center;
						}
					}
				}

				.image-wrapper {
					width: 100%;
					display: flex;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				h1 {
					@extend .headline;
				}
			}

			.trusted {
				position: absolute;
				bottom: var(--unit);
				right: var(--unit);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					display: none;
				}

				span {
					padding: 0.5rem;
					margin: 0 0.5rem;
					background: black;
					color: white;
					border-radius: var(--border-radius-light);
				}
			}

			.usps {
				margin-top: var(--unit);
				margin-bottom: calc(var(--unit) * -2);
				padding: var(--unit);
				background: white;
				border: 2px solid black;
				border-radius: var(--br24);

				display: flex;
				gap: var(--unit);
				flex-flow: column;

				@media #{base.$smobile}, #{base.$mobile} {
					margin-left: calc(var(--unit) * -1);
					margin-right: calc(var(--unit) * -1);
					border-radius: 0;
					border-top: 2px solid black;
					border-left: none;
					border-right: none;
					padding: 1rem;
				}
			}
		}
	}

	#benefits-grid {
		.container {
			border-radius: 0 !important;
		}

		.title-wrapper {
			grid-column: 1/21;
			margin-bottom: var(--unit);

			h4 {
				margin-top: 2rem;
			}
		}

		ul {
			grid-column: 1/21;
			display: grid;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile} {
				grid-template-columns: repeat(1, 1fr);
			}

			@media #{base.$tablet} {
				grid-template-columns: repeat(2, 1fr);
			}

			@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
				grid-template-columns: repeat(3, 1fr);
			}

			@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
				li:first-child {
					background-size: 250%;
					background-position-x: 30%;
				}

				li:nth-child(2) {
					background-size: 250%;
					background-position-x: 60%;
				}

				li:nth-child(3) {
					background-size: 250%;
					background-position-x: 90%;
				}

				li:nth-child(4) {
					background-size: 250%;
					background-position-x: 30%;
					background-position-y: 50%;
				}

				li:nth-child(5) {
					background-size: 250%;
					background-position-x: 60%;
					background-position-y: 50%;
				}

				li:nth-child(6) {
					background-size: 250%;
					background-position-x: 90%;
					background-position-y: 50%;
				}
			}

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				li {
					padding: 1rem;
					border-left: 0;
					border-top: 0;
					border-right: 0;
					border-bottom: 0;
					border-radius: 0;
				}

				li:first-child {
					background-size: 200%;
					background-position-x: 10%;
					background-position-y: 0%;
				}

				li:nth-child(2) {
					background-size: 200%;
					background-position-x: 20%;
					background-position-y: 20%;
				}

				li:nth-child(3) {
					background-size: 200%;
					background-position-x: 30%;
					background-position-y: 40%;
				}

				li:nth-child(4) {
					background-size: 200%;
					background-position-x: 40%;
					background-position-y: 60%;
				}

				li:nth-child(5) {
					background-size: 200%;
					background-position-x: 50%;
					background-position-y: 80%;
				}

				li:nth-child(6) {
					background-size: 200%;
					background-position-x: 60%;
					background-position-y: 100%;
				}
			}

			li {
				background-image: url("~@Assets/img/kycd-pearl-warm-background.jpg");
				display: flex;
				flex-flow: column;
				gap: 1rem;
				//border: 2px solid black;
				border-radius: var(--br24);
				padding: calc(var(--unit) / 2);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					padding: 1rem 2rem;
					border-left: 0;
					border-top: 0;
					border-right: 0;
					border-bottom: 0;
				}

				.image-wrapper {
					height: 124px;
					display: flex;
					align-items: center;

					img {
						width: 100px;
					}
				}
			}

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				li {
					//justify-content: center;
					//align-items: center;
				}
			}
		}
	}

	#the-nft {
		overflow: hidden;

		@media #{base.$smobile}, #{base.$mobile} {
			overflow: visible;
		}

		.wrapper {
			padding: var(--unit);
			grid-column: 1/23;
			gap: var(--unit);
			display: flex;

			@media #{base.$smobile}, #{base.$mobile} {
				padding: var(--unit) 0;
			}

			.text-wrapper,
			.illustration {
				width: 50%;
				height: 100%;
				display: flex;
				flex-flow: column;

				h4 {
					line-height: 170%;
					display: flex;
					height: 100%;
					align-items: center;
				}

				p {
					margin-top: var(--unit);
					color: black;

					ul {
						li {
							list-style: initial;
							color: black;
						}
					}
				}
			}

			.illustration {
				img {
					width: 100%;
				}
			}
		}

		@media #{base.$smobile}, #{base.$mobile} {
			.wrapper {
				flex-flow: column;

				.headline-wrapper {
					margin-bottom: 1rem;
				}
			}

			.text-wrapper,
			.illustration {
				width: 100% !important;
			}

			.illustration {
			}
		}
	}
}

.start-kyc {
	margin-top: -1rem;

	.kyc-button.black {
		padding-left: 0px !important;
		padding-right: 16px !important;
		background: black;

		span,
		i {
			color: white;
		}

		&:after,
		&:before {
			display: none;
		}
	}
}
