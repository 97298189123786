@use "@Style/base";

#mobile-navigation {
	@media #{base.$fullhd}, #{base.$widescreen}, #{base.$desktop} {
		display: none;
	}

	position: fixed;
	bottom: 0;
	z-index: 100;
	align-items: center;
	width: 100%;

	.nav-wrapper {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 10;

		.get-verified,
		.menu-opener {
			height: var(--button-height);
			display: inline-flex;
			justify-content: space-between;
			padding: 0 1rem;
			align-items: center;
			outline: none;
			@extend .tr2;
			cursor: pointer;

			span {
				font-family: gilroyRegular;
			}
		}

		.menu-opener {
			background: black;
			display: none;

			span,
			i {
				color: white;
			}

			i {
				margin-left: 1rem;
			}
		}

		.get-verified {
			width: 100%;
			background: var(--kyc-dark-blue);

			span,
			i {
				text-align: center;
				color: white;
			}
		}
	}

	.mobile-menu {
		background: black;
		position: absolute;
		width: 100%;
		height: 0;
		bottom: 0;
		//bottom: 48px;
		z-index: 0;
		@extend .tr2;
		display: none;

		&.active {
			height: 330px;
			padding: 1rem;
		}

		ul {
			padding-bottom: 3rem;

			li {
				margin-bottom: 2rem;

				a {
					font-family: gilroyRegular;
					color: white;
				}
			}
		}
	}

	@media #{base.$smobile} {
		i {
			display: none;
		}
	}
}
