// SCSS-heavy

@use "style/base/grid";
@use "style/base/animations";
@use "style/base/helpers";
// rest was here
@use "style/base/z-index";
@use "style/base/typography";
@use "style/base/global-constants.scss";

// CSS3 Custom Properties (CSS Variables)
@use "style/base/reset";
@use "style/base/colors-and-variables";

@use "./modal.scss";

@use "./components/button/button.component.scss";
@use "./components/container/container.component.scss";
@use "./components/feedback-flow-modal/feedback-flow-modal.component.scss";
@use "./components/footer/footer.component.scss";
@use "./components/hero/hero.component.scss";
@use "./components/input/input.component.scss";
@use "./components/join-waitlist/join-waitlist.component.scss";
@use "./components/loading-spinner/loading-spinner.component.scss";
@use "./components/mobile-nav/mobile-nav.component.scss";
@use "./components/nav/nav.component.scss";
@use "./components/open-positions/open-positions.component.scss";
@use "./components/single-select-list/single-select-list.component.scss";
@use "./components/single-select/single-select.component.scss";
@use "./components/svg-components/svg.components.scss";

@use "src/pages/join-us/app-page.scss";
@use "./pages/company-page/company-page.component.scss";
@use "./pages/contact-page/contact-page.scss";
@use "./pages/page-email-confirm/page-email-confirm.component.scss";
@use "./pages/page-faq/page-faq.component.scss";
@use "./pages/page-front-end-developer/page-front-end-developer.scss";
@use "./pages/page-grid/grid-page.component.scss";
@use "./pages/page-home/home-component.scss";
@use "./pages/page-home/home-page.animations.scss";
@use "./pages/page-privacy-policy/page-privacy-policy.component.scss";
@use "./pages/page-terms-and-conditions/page-terms-and-conditions.component.scss";
@use "./pages/partners-page/partners-page.component.scss";
@use "./pages/page-not-found/page-not-found";

[data-tooltip] {
	position: relative;

	&:hover::before {
		color: white;
		content: attr(data-tooltip);
		display: block;
		background: black;
		padding: 3px 9px;
		border-radius: 2px;
		position: absolute;
		z-index: 100;
		width: max-content;
		font-size: 12px;
		top: -40%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		//left: 15%;
	}
}

#root {
	justify-content: space-between;
	flex: 1;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

body {
	m-modal .m-modal-bg {
		top: var(--unit);
		left: var(--unit);
		right: var(--unit);
		bottom: var(--unit);
		width: initial;
		height: initial;
		position: fixed;
		cursor: pointer;

		z-index: 12;
		backdrop-filter: blur(145px);
		background-color: rgba(white, 0.01);
	}

	m-modal .m-modal-container {
		position: fixed;
		margin: auto;
		z-index: 13;
		max-width: initial;

		//width: calc(22*var(--unit));
		//max-width: 100%;
		//left: 50%;
		//top: 50%;

		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 670px;

		max-height: 98vh;
		box-sizing: border-box;
		transform: initial;
		outline: 0;
		border-radius: 6px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.m-web3Modal {
			background: white;
			padding: var(--unit);
			border-top: 3px solid var(--kyc-normal-blue);

			.m-web3ModalHeader__title {
				font-weight: 400;
				color: var(--kyc-dark-blue);
			}

			.m-web3Modal__header {
				padding: 0;
			}

			.m-web3ModalHeader__text {
				font-weight: 400;
				color: var(--kyc-dark-blue);
			}

			.m-web3Modal__body {
				padding: 0;

				.m-web3ModalProvider__body {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-flow: row;
					cursor: pointer;
					width: 100%;
					height: 6rem;
					padding: 0 2rem;
					margin-bottom: 20px;
					/* background-color: #fff; */
					border: 2px solid var(--inactive);
					border-radius: 7px;
					@extend .tr2;

					.m-web3ModalProviderBody__name {
						font-family: AvantgardeBook;
						font-weight: 400;
						font-size: 18px;
						color: var(--kyc-dark-blue);
						@extend .tr2;
					}

					&:hover {
						border: 2px solid var(--kyc-normal-blue);

						.m-web3ModalProviderBody__name {
							color: var(--kyc-normal-blue);
						}
					}
				}
			}

			.m-web3Modal__footer {
				padding: 0;
			}

			.m-web3ModalFooter__text {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2rem;
				height: 2rem;
				position: absolute;
				right: 1rem;
				top: 1rem;
				//color: var(--kyc-dark-blue);
				//background: white;

				&:after {
					content: "close";
					font-family: "material icons";
					color: var(--kyc-dark-blue);
					width: 2rem;
					height: 2rem;
					background: white;
					border-radius: 999rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--border);
					@extend .tr2;
				}

				&:hover {
					&:after {
						background: var(--kyc-normal-blue);
						color: white;
					}
				}
			}
		}
	}
}

/* CONNECT BUTTON */

#main-nav {
	.wallet-connect-button {
		width: var(--connect-button-width);
	}
}

/* HOMEPAGE - GET VERIFIED */

#get-verified {
	display: inline-flex;
	justify-content: space-between;
	padding-left: 2rem;
	padding-right: 2rem;
	align-items: center;
	outline: none;
	border: 1px solid transparent;
	border-radius: var(--border-radius-light);
	cursor: pointer;
	height: var(--button-height);
	@extend .tr2;

	background: var(--kyc-normal-blue);

	span {
		font-family: var(--light-font);
		font-weight: 200;
		text-transform: uppercase;
		font-size: 18px;
		margin-left: 0;
		color: white;
		@extend .tr2;
	}

	.nav {
		color: white;
		width: 0;
		opacity: 0;
		visibility: hidden;
		transform: translateX(-1rem);
		@extend .tr2;
	}

	.arrow {
		@include helpers.rotate(0);
		@extend .tr2;
		margin-left: 1rem;
		color: white;
	}

	&:hover {
		span {
			margin-left: 1rem;
		}

		.nav {
			width: auto;
			opacity: 1;
			visibility: visible;
			transform: translateX(0rem);
		}

		.arrow {
			@include helpers.rotate(-45deg);
			@extend .tr2;
		}
	}
}
