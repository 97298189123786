.title-icons {
	padding: 1rem;
	border-radius: 999rem;
	margin-bottom: 1rem;

	outline: 10px solid var(--kyc-dark-blue-35);
	background: var(--kyc-normal-blue);
	color: white;

	&.alert {
		outline: 10px solid var(--kyc-red-35);
		background: var(--kyc-red);
		color: white;
	}

	&.success {
		background: var(--kyc-green);
		outline: 10px solid var(--kyc-green-35);
	}
}

.tag {
	font-size: 14px;
	padding: 4px 10px;
	display: inline-flex;
	border-radius: 2px;

	&.green {
		background: var(--kyc-green);
		color: var(--kyc-dark-blue);
	}

	&.blue {
		background: var(--kyc-normal-blue);
		color: white;
	}

	&.red {
		background: var(--kyc-red);
		color: white;
	}
}
