@use "@Style/base/animations";

:host {
	display: flex;
	align-items: center;
	justify-content: center;
}

svg.blue {
	path {
		fill: #2aa9e0;
	}
}

svg.white {
	path {
		fill: white;
		@extend .tr2;
	}

	&:hover {
		path {
			fill: var(--kyc-normal-blue);
		}
	}
}
