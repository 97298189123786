@use "@Components/button/button.component";
@use "@Style/base";

#terms-and-conditions {
	.text-wrapper {
		ol > li:before {
			content: counters(item, ".") ".";
			counter-increment: item;
			margin-left: -16px;
			padding: 10px;
			background: black;
			color: white;
			margin-right: 10px;
			font-family: var(--display-font);
		}

		ol > li > ol li:before {
			margin-left: -36px !important;
			line-height: 3em;
		}

		ol > li > ol > li > ul li {
			list-style: disc;
			counter-reset: none;

			&:before {
				display: none !important;
			}
		}

		ol {
			counter-reset: item;

			li {
				display: block;
			}
		}

		h4 > a.link-button {
			&::after {
				bottom: -9px;
				height: 0px;
			}
			&:hover {
				&::after {
					height: 9px;
					bottom: -9px;
				}
			}
			margin-bottom: 0.5em;
		}

		display: flex;
		flex-flow: column;
		width: 100%;
		grid-column: 1 / 24;

		ul {
			margin: 1rem;

			li {
				list-style: initial;
			}
		}

		ol,
		ul,
		p {
			font-weight: 400;
			font-family: var(--primary-font);

			b {
				line-height: 3em;
				font-family: var(--primary-font);
				font-weight: 800;
			}
		}

		a {
			display: inline-block;
			box-sizing: border-box;
			padding: 0;
			position: relative;
			@extend .tr2;
			@extend .link-button;
		}

		ol {
			li {
				list-style: number;
				margin-top: 1.5rem;

				@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
					margin-left: var(--unit);
				}

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					margin-left: 1rem;
				}

				@extend p;

				ol {
					li {
						list-style: lower-alpha;
						@extend p;

						ol {
							li {
								list-style: lower-roman;
							}
						}
					}
				}
			}
		}
	}
}
