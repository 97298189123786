@use "@Style/base/animations";

:host {
	display: flex;
	align-items: center;
	justify-content: center;
}

svg.default {
	fill: #7289da;
}

svg.white {
	fill: white;
	@extend .tr2;

	&:hover {
		fill: var(--kyc-normal-blue);
	}
}
