@use "@Style/base";
@use "../button/button.component";

.menu-font {
	font-family: var(--primary-font);

	@media #{base.$smobile} {
		font-size: 14px;
	}
	@media #{base.$mobile} {
		font-size: 16px;
	}
	@media #{base.$tablet} {
		font-size: 16px;
	}
	@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		font-size: 1vw;
	}
}

#main-nav {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 14;
	background: white;
	height: var(--unit);
	min-height: 58px;
	border-bottom: 1px solid var(--border);

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		position: relative;
		border-bottom: none;
		padding-left: 10px;
	}

	.desktop-menu-wrapper {
		align-items: center;
		justify-content: space-between;
		display: flex;
		width: 100%;
		height: 100%;
		padding-right: var(--unit);

		@media #{base.$smobile}, #{base.$mobile} {
			padding-right: 0;
		}
	}

	.demo-message {
		background-color: white;
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: -1rem;
		margin-bottom: 1rem;
		align-items: center;
		padding: 0.5rem 1rem;

		i {
			color: var(--kyc-red);
			margin-right: 0.5rem;
		}

		span {
			@extend .menu-font;
		}

		h4 {
		}
	}

	#logo {
		display: flex;
		cursor: pointer;
		position: relative;
		left: 1.2vw;
		align-items: center;

		.logomark {
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 100%;
				//bottom: 0;
				z-index: -1;
				background-color: black;
				transition: all 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: -2;
				background: var(--kyc-cybergreen);
				transition: all 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
			}

			svg {
				height: 2vw;
				width: auto;
				padding: 0.4vw 0.7vw;
				transition: all 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					height: 1.5rem;
					padding: 7px 9px;
				}

				path {
					@extend .tr2;
					fill: white;
				}
			}
		}

		.logotype {
			svg {
				height: 1.8rem;
				@extend .tr2;

				@media #{base.$smobile}, #{base.$mobile} {
					height: 1.2rem;
				}
			}
		}

		.beta {
			background: black;
			color: white;
			padding: 2px 4px;
			font-size: 12px;
			line-height: 12px;
			margin-left: 6px;
		}
	}

	#logo:hover {
		.logomark {
			&:after {
				height: 0%;
			}

			&:before {
				@extend .tr2;
				//transition: all 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
			}

			path {
				fill: black;
			}
		}
	}

	#logo:active {
		.logomark {
			&:after {
			}

			&:before {
				background: var(--kyc-normal-blue);
			}

			path {
				fill: white;
			}
		}
	}

	.menu {
		display: flex;
		align-items: center;
		position: relative;

		& > ul {
			display: flex;
			align-items: center;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				display: none;
			}

			& > li {
				@extend .tr2;

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet}, #{base.$desktop} {
					margin-right: 1rem;
				}

				@media #{base.$widescreen}, #{base.$fullhd} {
					margin-right: 2rem;
				}

				& > a {
					padding: 4px 0px;
					color: black;
					background: rgba(white, 0.1);
					border-bottom: 2px solid var(--kyc-cyberpunk);
					font-family: var(--primary-font);
					@extend .menu-font;
					@extend .tr2;

					&:hover {
						background: black;
						color: var(--kyc-cybergreen);
					}

					&:active {
						background: var(--kyc-cybergreen);
						color: black;
					}

					&.selected {
						background: var(--kyc-normal-blue);
						color: white;
					}
				}
			}

			li.secondary {
				a {
					display: flex;
					background: white;
					border: 2px solid black;
					height: var(--button-height);
					align-items: center;
					padding-left: 2rem;
					padding-right: 2rem;
					border-radius: var(--border-radius-light);

					a {
						border: none;
						background: none;
					}

					&:hover {
						background: black;

						a {
							color: var(--kyc-cybergreen);
						}
					}
				}
			}

			li.primary {
				a {
					@extend .kyc-button;
					background: var(--kyc-normal-blue);
					border-radius: var(--border-radius-light);
					transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
					box-shadow: black 4px 4px 0px 0px;
					border: 2px solid black;

					i,
					span {
						color: white;
						transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
					}

					i {
						left: 0;
					}

					span {
						font-family: var(--primary-font);
						text-transform: none;
					}

					&:hover {
						background: var(--kyc-cybergreen);

						i,
						span {
							color: black;
						}

						i {
							left: 1rem;
						}
					}

					&:focus {
					}

					&:active {
						box-shadow: black 0px 0px 0px 0px;
					}
				}
			}
		}
	}

	#open-app {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.kyc-button {
		background: black;
		@extend .tr2;

		&.white {
			background: black;
			border-color: black;
		}

		span,
		i {
			color: white;
			@extend .tr2;
		}

		&:hover {
			background: var(--kyc-cybergreen);

			span,
			i {
				color: black;
			}
		}

		@media #{base.$smobile}, #{base.$mobile} {
			display: none;
		}
	}

	#join-us {
		display: flex;
	}
}

.menu-opener-wrapper {
	position: relative;

	.menu-opener {
		position: relative;
		z-index: 10;
		cursor: pointer;
		width: 3rem;
		height: 3rem;
		align-items: center;
		justify-content: center;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			display: flex;
		}

		@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
			display: none;
		}

		i {
			color: black;
		}
	}
}

.menu-closer {
	position: absolute;
	z-index: 15;
	cursor: pointer;
	width: 4rem;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: black;
	color: white;
	border-bottom: 2px solid black;
	right: -4rem;
	top: 0;
	border-left: 2px solid black;
	border-right: 2px solid black;
	@extend .tr2;

	@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		display: none;
	}

	&:active {
		background: var(--kyc-cybergreen);
		color: black;
	}
}

#mobile-menu {
	background: white;
	position: fixed;
	height: 100%;
	bottom: 0;
	top: 0;
	border-right: 2px solid black;
	left: -110%;

	width: calc(100% - 4rem);
	right: 4rem;
	z-index: 0;
	@extend .tr2;

	&.active {
		z-index: 100;
		left: 2px;
		height: 100%;
		padding: 1rem;
	}

	ul {
		padding-bottom: 3rem;
		padding-top: 3rem;

		li {
			margin-bottom: 2rem;

			a {
				@extend .md-text;
				font-family: var(--primary-font);
				color: black;
			}
		}
	}

	ul.secondary {
		display: flex;
		justify-content: space-between;
	}
}

#network-selector {
	display: flex;
	gap: 1rem;

	& > li {
		& > div {
			/* This is the button part of the dropdown */
			& > .kyc-button {
				&:after,
				&:before {
					display: none;
				}

				.arrow {
					rotate: 180deg;
				}

				/* This is the button in the button */
				.kyc-button {
					border: none;
					background: none;

					.arrow {
						display: none;
					}
				}
			}

			& > div {
				background: white;

				/* This is the list element in the dropdown */
				& > .kyc-button {
					background: white !important;
					border: none !important;
					border-radius: 0 !important;

					span {
						color: black !important;
					}

					&:hover {
						background: var(--kyc-cybergreen) !important;
					}
				}
			}
		}
	}

	li:nth-child(2) {
		.kyc-button {
			//width: 270px !important;
		}
	}
}

.coming-soon.launch {
	img {
		border: 10px solid var(--kyc-cybergreen) !important;
	}

	h1 {
		color: var(--kyc-cybergreen) !important;
	}
}

.coming-soon {
	padding: var(--unit);
	background-image: url("~@Assets/img/kycd-pearl-background.jpg");
	background-size: cover;

	display: flex;
	flex-flow: column;

	width: 100%;
	height: 100vh;

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		flex-flow: column;
	}

	@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		flex-flow: column;
		gap: var(--unit);
	}

	.logo-wrapper {
		padding: 2rem;
		border-radius: var(--br24);
		width: 100%;
		background: black;
		display: flex;
		align-items: center;
	}

	.coming-soon-content-wrapper {
		padding: 2rem;
		border-radius: var(--br24);
		display: flex;
		gap: var(--unit);
		justify-content: space-between;
		background: black;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			flex-flow: column;
			margin-top: var(--unit);
		}

		.text {
			width: max-content;
			display: flex;
			flex-flow: column;
			justify-content: space-between;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				width: 100%;
			}

			h1 {
				font-family: var(--display-font);
				color: white;

				@media #{base.$superlarge}, #{base.$fullhd} {
					width: 50%;
				}
			}
		}

		.illustration {
			width: max-content;
			display: flex;
			position: relative;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				width: 100%;
			}

			span {
				width: 2px;
				height: 100%;
				background-color: var(--kyc-cybergreen);

				position: absolute;
				top: 0;
				bottom: 0;
			}

			.left {
				left: 20%;
			}
			.right {
				right: 20%;
			}

			img {
				border-radius: var(--br24);
				border: 2px solid white;
				overflow: hidden;
			}
		}
	}
}
