@use "./global-constants" as gc;

body {
	.xxl-text,
	h1 {
		font-family: var(--primary-font);
		font-weight: 100;

		@media #{gc.$smobile}, #{gc.$mobile} {
			font-size: 2.5rem;
			line-height: 3rem;
		}

		@media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
			font-size: 3vw;
		}
	}

	.xl-text,
	h2 {
		font-family: var(--primary-font);
		font-weight: 100;

		@media #{gc.$smobile}, #{gc.$mobile} {
			font-size: 22px;
		}

		@media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
			font-size: 2vw;
		}
	}

	.lg-text,
	h3 {
		font-family: var(--primary-font);

		@media #{gc.$smobile}, #{gc.$mobile} {
			font-size: 18px;
		}

		@media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
			font-size: 1.8vw;
		}
	}

	.md-text,
	h4 {
		font-family: var(--primary-font);

		@media #{gc.$smobile}, #{gc.$mobile} {
			font-size: 16px;
		}

		@media #{gc.$tablet}, #{gc.$desktop} {
			font-size: 1.8vw;
		}

		@media #{gc.$widescreen}, #{gc.$fullhd} {
			font-size: 1.2vw;
		}

		/*

    @media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
      font-size: 1.2vw;
    }

		@media #{gc.$tablet}, #{gc.$desktop} {
			font-size: 1.8vw;
		}

		@media #{gc.$widescreen}, #{gc.$fullhd} {
			//font-size: 1.5vw;
			font-size: 1.2vw;
		}
    */
	}

	.text-sm,
	p {
		font-family: var(--primary-font);
		line-height: 170%;

		@media #{gc.$smobile}, #{gc.$mobile} {
			font-size: 14px;
		}

		@media #{gc.$tablet}, #{gc.$desktop} {
			font-size: 1.3vw;
		}

		@media #{gc.$widescreen}, #{gc.$fullhd} {
			font-size: 1vw;
		}

		@media #{gc.$superlarge} {
			font-size: 0.9vw;
		}

		/*
		@media #{gc.$smobile} {
			font-size: 14px;
		}
		@media #{gc.$mobile} {
			font-size: 14px;
		}
		@media #{gc.$tablet} {
			font-size: 16px;
		}
		@media #{gc.$desktop} {
			font-size: 18px;
		}
		@media #{gc.$widescreen} {
			//font-size: 22px;
			font-size: 18px;
		}
		@media #{gc.$fullhd} {
			font-size: 18px;
		}
		@media #{gc.$superlarge} {
			font-size: 0.9vw;
		}
     */
	}

	p {
		//line-height: 130%;
	}

	@media #{gc.$smobile}, #{gc.$mobile} {
		font-size: 10px;
	}

	@media #{gc.$tablet}, #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
		font-size: 0.6vw;
	}

	.text-xs {
		font-family: var(--primary-font);

		@media #{gc.$smobile} {
			font-size: 10px;
		}
		@media #{gc.$mobile} {
			font-size: 10px;
		}
		@media #{gc.$tablet} {
			font-size: 12px;
		}
		@media #{gc.$desktop} {
			font-size: 12px;
		}
		@media #{gc.$widescreen} {
			font-size: 14px;
		}
		@media #{gc.$fullhd} {
			font-size: 14px;
		}
		@media #{gc.$superlarge} {
			font-size: 0.6vw;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul li,
	ol li {
		&.bold {
			font-family: var(--display-font);
			font-weight: 800;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
ul li,
ol li,
b {
	font-family: var(--primary-font);
	font-weight: 400;
	color: black;
}

.headline {
	display: inline;
	box-decoration-break: clone;
	font-family: var(--display-font);
	background: black;
	color: white;
	position: relative;
	top: -2px;
	text-transform: uppercase;
	border-radius: var(--border-radius-light);

	@media #{gc.$smobile}, #{gc.$mobile} {
		padding: 4px;
	}

	@media #{gc.$tablet} {
		padding: 0.9vw;
	}

	@media #{gc.$desktop}, #{gc.$widescreen}, #{gc.$fullhd} {
		padding: 0.9vw;

		//padding: 1.2vw;
		//line-height: 3.6vw;
	}

	span {
		color: var(--kyc-cybergreen);
		margin-right: 0.5vw;
	}
}

::selection {
	background: var(--kyc-cybergreen);
	color: black;
}

body {
	display: flex;
	flex-flow: column;
}

ol,
ul {
	padding-inline-start: 0;
}
