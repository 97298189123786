@use "@Style/base/animations";

@mixin pseudo($bg-color) {
	display: block;
	background-color: $bg-color;
	content: "";
}

@mixin rotate($rotation) {
	transition-property: $rotation;
	transform: rotate($rotation);
}

.interactive {
	cursor: pointer;
	@extend .tr2;
}

.mgt-5 {
	margin-top: 5rem;
}

.mgt-10 {
	margin-top: 10rem;
}

.desaturate {
	filter: grayscale(100%) !important;
}
.saturate {
	filter: grayscale(0%) !important;
}

.is-invisible {
	visibility: hidden;
	opacity: 0;
}

.is-visible {
	visibility: visible;
	opacity: 1;
}

.hypen {
	display: block;
	content: "";
	height: 1px;
	width: 30px;
	margin: 0 0.5rem;
	border: none;
	outline: none;
}
