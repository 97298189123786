/* NEW */
@font-face {
	font-family: "machineRegular";
	src:
    /* Modern Browsers */ url("NeueMachina-Regular.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

/* NEW */
@font-face {
	font-family: "machineLight";
	src:
    /* Modern Browsers */ url("NeueMachina-Light.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "sonarDao";
	src:
    /* Modern Browsers */ url("Sonaar-DAO-Light.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "sonaarLight";
	src:
    /* Modern Browsers */ url("Sonaar-mono-light.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

/* OLD */
@font-face {
	font-family: "gilroyRegular";
	src:
    /* Modern Browsers */ url("Gilroy-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gilroyRegular";
	src:
    /* Modern Browsers */ url("Gilroy-Regular.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gilroyLight";
	src:
    /* Modern Browsers */ url("Gilroy-Light.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gilroyBold";
	src:
    /* Modern Browsers */ url("Gilroy-ExtraBold.woff2") format("woff2");

	font-weight: normal;
	font-style: normal;
}
