@use "@Style/base";

#open-positions {
	grid-column: 1/24;
	display: flex;
	flex-flow: column;

	.container {
		@media #{base.$smobile},
    #{base.$mobile},
    #{base.$tablet} {
			padding: 1rem;
		}

		@media #{base.$desktop},
    #{base.$widescreen},
    #{base.$fullhd} {
			padding: calc(var(--unit) * 2);
		}
	}

	.title {
		@media #{base.$smobile},
    #{base.$mobile},
    #{base.$tablet} {
			padding: 6rem 1rem 4rem 1rem;
			grid-column: 1/24;
		}

		@media #{base.$desktop} {
			padding: 8vh 0 4vh 0;
			grid-column: 3/21;
		}

		@media #{base.$widescreen},
    #{base.$fullhd} {
			padding: 10vh 0 6vh 0;
			grid-column: 3/21;
		}

		i {
			padding: 1rem;
			background: var(--kyc-normal-blue);
			border-radius: 999rem;
			margin-bottom: 1rem;
			outline: 10px solid var(--kyc-dark-blue-35);
		}

		i,
		h2,
		h4 {
			color: white;
		}

		h4 {
			margin-top: 1rem;
		}
	}

	ul.careers-listing {
		&.grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: var(--unit);

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				display: flex;
				flex-flow: column;

				li {
					padding: 1rem;

					button {
						padding-left: 1rem;
						padding-right: 1rem;

						span {
							font-size: 16px;
						}
					}
				}
			}

			li {
				//border: 1px solid white;
				background: var(--kyc-dark-blue-35);
				padding: 2rem;
				display: flex;
				flex-flow: column;
				justify-content: space-between;

				.tag {
					@extend .text-xs;
					padding: 4px 10px;
					background: var(--kyc-normal-blue);
					color: white;
					display: inline-flex;
				}

				.job-title {
					display: flex;
					justify-content: space-between;

					h4 {
						span {
							@extend .text-xs;
							padding: 4px 10px;
							background: var(--kyc-green);
							color: var(--kyc-dark-blue);
							display: inline-flex;
						}
					}
				}

				.smaller-text {
					font-size: 18px;
					line-height: 130%;
				}

				h4 {
					color: white;
					margin-bottom: 2rem;
				}

				p {
					color: white;
				}

				button {
					margin-top: 2rem;
					width: max-content;
				}
			}
		}
	}

	.footer {
		margin-top: 2rem;

		h4 {
			color: white;

			span {
				display: inline-block;
				box-sizing: border-box;
				padding: 0;
				position: relative;
				margin-left: 0.5rem;

				&:after {
					position: absolute;
					bottom: 0px;
					height: 9px;
					width: 100%;
					background-color: var(--kyc-green-50);
					content: "";
					display: block;
					@extend .tr2;
				}

				&:hover {
					//background-color: var(--kyc-normal-blue-35);
					color: var(--kyc-green);

					&:after {
						background-color: var(--kyc-green-75);
					}
				}
			}
		}
	}
}
