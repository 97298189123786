@use "@Components/button/button.component" as button;
@use "@Style/base";
@use "@Style/base/global-constants" as gc;

#job-description {
	position: relative;
	z-index: 100;

	.back-button {
		height: var(--unit);
		display: flex;
		align-items: center;
		max-width: max-content;
		background: white;

		position: absolute;
		top: -5%;
		padding: 0 1rem;
		cursor: pointer;
		@extend .tr2;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			width: 100%;
			padding: 1rem;
		}

		i,
		span {
			color: var(--kyc-dark-blue);
			width: max-content;
		}

		span {
			font-family: var(--primary-font);
			@extend .text-sm;
			@extend .tr2;
		}

		&:hover {
			background: var(--kyc-normal-blue);

			span,
			i {
				color: white;
			}
		}
	}

	.job-description {
		grid-column: 1 / 12;
		padding-right: var(--unit);
	}

	.job-highlights {
		grid-column: 12 / 18;
		background: var(--kyc-green);
		border-top: 3px solid var(--kyc-normal-blue);
		padding: var(--unit);
		display: flex;
		flex-flow: column;
		justify-content: space-between;

		span {
			font-family: var(--primary-font);
		}
	}

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		.job-description,
		.job-highlights {
			grid-column: 1/24;
		}

		.job-highlights {
			flex-flow: row;
			position: fixed;
			z-index: 10;
			left: 0;
			right: 0;

			.comment {
				display: none;
			}
		}
	}

	@media #{base.$smobile}, #{base.$mobile} {
		.job-highlights {
			bottom: 48px;
		}
	}

	@media #{base.$tablet} {
		.job-highlights {
			bottom: 0px;
		}
	}

	.text-wrapper {
		grid-column: 1 / 24;

		ul.skill-tags {
			display: flex;
			margin-top: 1rem;
			flex-wrap: wrap;

			li {
				padding: 1rem;
				margin-right: 1rem;
				border: 2px solid var(--kyc-normal-blue);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					margin-bottom: 1rem;
				}
			}
		}

		ul.skill-tags.perks {
			display: flex;

			li {
				border: none;
				background: var(--kyc-green);
				color: white;

				//background: white;
				//margin-right: 1rem;
				//box-shadow: 0 0 11px #00000026;
			}
		}
	}
}

#get-in-touch {
	grid-column: 1/24;
	display: flex;
	flex-flow: column;

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		padding: 1rem;
	}

	@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		padding: calc(var(--unit) * 2);
	}

	.title {
		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			padding: 6rem 1rem 4rem 1rem;
			grid-column: 1/24;
		}

		@media #{base.$desktop} {
			padding: 8vh 0 4vh 0;
			grid-column: 3/21;
		}

		@media #{base.$widescreen}, #{base.$fullhd} {
			padding: 10vh 0 6vh 0;
			grid-column: 3/21;
		}

		i {
			padding: 1rem;
			background: var(--kyc-normal-blue);
			border-radius: 999rem;
			margin-bottom: 1rem;
			outline: 10px solid var(--kyc-dark-blue-35);
		}

		i,
		h2,
		h4 {
			color: white;
		}

		h4 {
			margin-top: 1rem;
		}
	}

	.buttons {
		@media #{base.$mobile}, #{base.$mobile} {
			.kyc-button {
				width: 100%;
			}

			.discord {
				margin-bottom: 1rem;
			}
		}

		.discord {
			margin-right: 2rem;
			width: 292px;

			.nav {
				//display: none;
			}
		}

		.ghost {
			background: transparent;
			border: 2px solid var(--kyc-normal-blue);

			span {
				font-family: var(--primary-font);
				margin-right: 1rem;
			}

			i,
			span {
				color: white;
			}
		}
	}

	.footer {
		margin-top: 2rem;

		h4 {
			color: white;

			span {
				display: inline-block;
				box-sizing: border-box;
				padding: 0;
				position: relative;
				margin-left: 0.5rem;

				&:after {
					position: absolute;
					bottom: 0px;
					height: 9px;
					width: 100%;
					background-color: var(--kyc-green-50);
					content: "";
					display: block;
					@extend .tr2;
				}

				&:hover {
					//background-color: var(--kyc-normal-blue-35);
					color: var(--kyc-green);

					&:after {
						background-color: var(--kyc-green-75);
					}
				}
			}
		}
	}
}

#application-process {
	.wrapper {
		@media #{base.$smobile} {
			grid-column: 1/3;
			padding: 2rem 1rem;
		}
		@media #{base.$mobile} {
			grid-column: 1/5;
			padding: 4rem 1rem;
		}
		@media #{base.$tablet} {
			grid-column: 2/8;
			padding: 4rem 0;
		}
		@media #{base.$desktop} {
			grid-column: 5/19;
		}
		@media #{base.$widescreen} {
			grid-column: 5/19;
		}

		@media #{base.$fullhd} {
			grid-column: 3/21;
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 4rem;
	}

	.the-process {
		display: flex;

		li {
			display: flex;
			flex-flow: column;
			text-align: center;
			align-items: center;
			width: 100%;

			span {
				padding: 1rem;
				background: var(--kyc-normal-blue);
				color: white;
				border-radius: 999rem;
				width: 1rem;
				height: 1rem;
			}

			p {
			}
		}

		@media #{base.$smobile}, #{base.$mobile} {
			flex-flow: column;

			li {
				margin-bottom: 1rem;
			}
		}
	}
}
