@use "src/style/base/global-constants" as constants;

$large-unit: 4.166666666666667vw;

$grid-columns: (
	4000px: constants.$fullhd-column-count,
	1217px: 12,
	1025px: 8,
	721px: 4,
	327px: 2,
);

@each $width, $column-count in $grid-columns {
	@media screen and (max-width: $width) {
		.grid {
			grid: 1fr / repeat($column-count, 1fr);
			position: relative;
			z-index: 0;
		}
	}
}

.container-wrapper {
	position: relative;

	@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
		margin: 2rem 0;
	}

	.grid-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: visible;
		z-index: 0;

		.grid {
			display: grid;
			position: relative;
			height: 100%;
			width: 100%;

			@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
				padding: 0 1rem;
			}

			span {
				width: 1px;
				background: var(--border);
			}

			span:first-child {
				background: transparent;
			}
		}
	}

	.container {
		position: relative;
		display: grid;
		border-radius: var(--br24);
		overflow: hidden;
	}
}

/* N is the count of the repaeting, S is the value */
@function column-looper($n, $s) {
	$column: "";
	@for $i from 1 through $n {
		$column: $column + $s + " ";
	}
	@return str_slice(unquote($column), 0, str_length($column)-1);
}

.container {
	z-index: 1;
	justify-content: center;
	margin: 0 auto;
	//background: red;
	/* display: grid; defined elsevhere, container, without container wrapper is a flex */

	&.no-grid {
		display: flex;
	}

	@media #{constants.$smobile} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			constants.$small-mobile-column-count,
			constants.$small-mobile-column-width
		);
	}

	@media #{constants.$mobile} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			constants.$mobile-column-count,
			constants.$mobile-column-width
		);
	}

	@media #{constants.$tablet} {
		max-width: calc(100% - 2rem);
		grid-template-columns: column-looper(
			constants.$table-column-count,
			constants.$table-column-width
		);
	}

	@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
		&.narrow {
			max-width: calc(100% - (#{constants.$grid-col-width * 6}));
			grid-template-columns: column-looper(18, constants.$narrow-col-width);
			/* Heres should be = 18 cols --> 24 - 6 gutter) */
		}

		&.normal {
			max-width: calc(100% - (#{constants.$grid-col-width * 4}));
			grid-template-columns: column-looper(20, constants.$normal-col-width);
			/* Heres should be = 20 cols --> 24 - 4 gutter) */
		}

		&.wide {
			max-width: calc(100% - (#{constants.$grid-col-width * 2}));
			grid-template-columns: column-looper(22, constants.$full-hd-col-width);
			/* Heres should be = 22 cols --> 24 - 2 gutter) */
		}

		&.full {
			max-width: 100%;
			grid-template-columns: column-looper(24, constants.$full-hd-col-width);
			/* Heres should be = 24 */
		}
	}
}

.container-wrapper {
	.pt-1 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-top: constants.$small-unit !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-top: $large-unit !important;
		}
	}

	.pt-2 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-top: calc(#{constants.$small-unit} * 2) !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-top: calc(#{$large-unit} * 2) !important;
		}
	}

	.pt-3 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-top: calc(#{constants.$small-unit} * 2) !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-top: calc(#{$large-unit} * 3) !important;
		}
	}

	.pb-1 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-bottom: constants.$small-unit !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-bottom: $large-unit !important;
		}
	}

	.pb-2 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-bottom: calc(#{constants.$small-unit} * 2) !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-bottom: calc(#{$large-unit} * 2) !important;
		}
	}

	.pb-3 {
		@media #{constants.$smobile}, #{constants.$mobile}, #{constants.$tablet} {
			padding-bottom: calc(#{constants.$small-unit} * 2) !important;
		}
		@media #{constants.$desktop}, #{constants.$widescreen}, #{constants.$fullhd} {
			padding-bottom: calc(#{$large-unit} * 3) !important;
		}
	}
}

.container-wrapper.pearl {
	.grid-wrapper {
		//display: none;
	}

	.container {
		background-image: url("~@Assets/img/kycd-pearl-background.jpg");
		background-size: cover;
	}
}

.container-wrapper.warm {
	.grid-wrapper {
		//display: none;
	}

	.container {
		background-image: url("~@Assets/img/kycd-pearl-warm-background.jpg");
		background-size: 100% 100%;
	}
}

.container-wrapper.dark {
	.grid-wrapper {
		display: none;
	}

	.container {
		background-image: url("~@Assets/img/kyc-dark-pearl-background.jpg");
		background-size: cover;
	}
}

.container-wrapper.purple {
	.grid-wrapper {
		display: none;
	}

	.container {
		background-color: var(--kyc-purple);
	}
}
