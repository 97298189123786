@use "@Style/base/animations";

.ng-star-inserted {
	padding: 0.5rem;
	border: 2px solid var(--inactive);
	border-radius: var(--border-radius-light);
	margin-top: 1rem;
	display: flex;
	align-items: center;

	font-family: gilroyRegular;
	color: var(--kyc-dark-blue);
	@extend .tr2;

	&:hover {
		border: 2px solid var(--kyc-normal-blue);

		input {
			box-shadow: inset 0 0 0 0.5rem var(--kyc-normal-blue);
		}
	}
}

input[type="radio"] {
	padding: 0;
	background-color: var(--inactive);
	border: 10px solid var(--inactive);
	box-sizing: content-box;
	border-radius: 50%;
	box-shadow: inset 0 0 0 0 white;
	cursor: pointer;
	font: inherit;
	outline: none;
	height: 1rem;
	width: 1rem;
	margin-right: 1rem;
	@extend .tr2;

	&:checked {
		background-color: white;
		box-shadow: inset 0 0 0 0.5rem var(--kyc-normal-blue);
		transition: background 0.15s, box-shadow 0.1s;
	}
}
