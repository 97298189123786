@use "@Style/base";

.error-modal {
	overflow: scroll;
	max-height: 100%;
}

.content-wrapper {
	border-top: 3px solid var(--kyc-normal-blue);
	margin: 0 auto;
	width: calc(100% - (var(--unit) * 6));

	/* Chrome 29+ */
	@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
		backdrop-filter: blur(145px);
		background: transparent;
	}

	@-moz-document url-prefix() {
		background: white !important;
	}
}

.modal {
	position: fixed;
	z-index: 11;
	visibility: visible;
	opacity: 1;
	@extend .tr2;

	.content-wrapper {
		max-height: 100%;
		transform: translateY(0);
		@extend .tr2;

		top: 0;
		visibility: visible;
		opacity: 1;
		@extend .tr2;
	}

	&.hidden {
		visibility: hidden;
		opacity: 0;

		.content-wrapper {
			visibility: hidden;
			opacity: 0;
			transform: translateY(200px);
			@extend .tr2;
		}
	}

	.overlay {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(#3a4be9, 0.2);

		display: flex;
		flex-flow: column;
		justify-content: center;
		align-content: center;
	}

	.content-wrapper {
		height: auto;
		z-index: 10;
		padding: var(--unit);

		.kyc-button {
			margin-top: 1rem;
		}

		.modal-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1rem;

			i {
				color: var(--kyc-normal-blue);
				padding: 0.5rem;
				border-radius: 999rem;
				background: white;
				cursor: pointer;
				outline: 10px solid transparent;
				@extend .tr2;

				&:hover {
					background: var(--kyc-normal-blue);
					color: white;
				}

				&:active {
					outline: 10px solid var(--kyc-normal-blue-35);
				}
			}
		}
	}
}

/* BLURRED CASE */
.modal.blurred {
	left: var(--unit);
	right: var(--unit);
	bottom: var(--unit);

	.overlay {
		background: transparent;
		padding: var(--unit);
	}

	.content-wrapper {
		backdrop-filter: blur(145px);
		padding: calc(var(--unit) / 2) var(--unit);
	}
}

/* CLASSIC CASE */
.modal.big {
	width: 100%;
	height: 100%;

	.content-wrapper {
		border-top: 3px solid var(--kyc-normal-blue);
		margin: 0 auto;
		width: calc(100% - (var(--unit) * 6));

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			width: calc(100% - 2rem);
		}

		/* Chrome 29+ */
		@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
			backdrop-filter: blur(145px);
			background: transparent;
		}

		@-moz-document url-prefix() {
			background: white !important;
		}
	}
}

.modal.small {
	width: 100%;
	height: 100%;

	.content-wrapper {
		border-top: 3px solid var(--kyc-normal-blue);
		max-width: 600px;
		height: auto;
		margin: 0 auto;
		background: white;
	}
}

.modal.medium {
	width: 100%;
	height: 100%;

	.content-wrapper {
		border-top: 3px solid var(--kyc-normal-blue);
		max-width: 800px;
		height: auto;
		margin: 0 auto;
		background: white;
	}
}

/* COOKIE POLICY CASE */
.modal.cookie-policy {
	.overlay {
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		align-content: flex-end;
		padding-left: calc(var(--unit) / 2);
		padding-bottom: calc(var(--unit) / 2);
		padding-right: calc(var(--unit) / 2);

		.content-wrapper {
			padding: 2rem;
			border-top: 3px solid var(--kyc-normal-blue);
			height: auto;
			background: white;
			margin: initial;

			@media #{base.$tablet}, #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
				width: 600px;
			}

			@media #{base.$smobile}, #{base.$mobile} {
				width: 100%;
			}

			.modal-head {
				font-family: var(--primary-font);
			}

			p {
				font-size: 16px;
			}

			.button-wrapper {
				display: flex;
				justify-content: space-between;

				@media #{base.$smobile}, #{base.$mobile} {
					flex-flow: column;

					.kyc-button {
						width: 100%;
					}
				}
			}

			.close-button {
				background: white;
				border: 2px solid black;
				@extend .tr2;

				span,
				i {
					color: black;
				}

				&:hover {
					background: black;

					span,
					i {
						color: white;
					}
				}
			}
		}
	}
}

/* FEEDBACK FORM CASE */
.modal.feedback-flow {
	width: 100%;
	height: 100%;

	.modal-head {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
		z-index: 10;
		position: relative;

		h4 {
			@extend .lg-text;
		}

		i {
			position: absolute;
			right: 0;
		}
	}

	.content-wrapper {
		border-top: 3px solid var(--kyc-normal-blue);
		max-width: 700px;
		height: auto;
		margin: 0 auto;
		background: white;
	}
}

#mobile-modal-root {
	margin-bottom: 1rem;
}
