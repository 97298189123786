:host {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
svg.default{
  path{
    fill: black
  }
}

svg.white {
  path {
    fill: white;
    @extend .tr2;
  }

  &:hover{
    path{
      fill: var(--kyc-normal-blue);
    }
  }
}

 */
