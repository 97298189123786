@use "@Style/base/animations";

.kyc-input {
	height: var(--button-height);
	display: inline-flex;
	justify-content: space-between;
	padding: 0 2rem;
	align-items: center;
	position: relative;
	box-shadow: 0 0 0 0 rgba(white, 0);
	background: white;
	color: var(--kyc-dark-purple);
	border-radius: var(--border-radius-light);

	font-size: 18px;
	@extend .tr2;

	::placeholder {
		color: var(--kyc-dark-purple);
	}

	&:hover {
		box-shadow: 0 0 0 10px var(--kyc-normal-blue-35);
	}

	&:focus {
		box-shadow: 0 0 0 10px var(--kyc-normal-blue-50);
		outline: none;
	}
}

.disabled {
	//background-color: black;
	position: relative;
	z-index: 10;
	border: 2px solid var(--kyc-green);
	box-shadow: 0 0 0 10px var(--kyc-green-35);

	/*
  &:after{
    display: block;
    content: '';
    //content: "done";
    //font-family: "material-icons";
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -10px;
    transform: rotate(45deg);
    left: 17px;
    background: red;
    z-index: 10000;
  }

   */
}
