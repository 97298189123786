@use "@Style/base";
@use "@Components/button/button.component";

#page-404 {
	margin-bottom: var(--unit);

	.wrapper {
		padding: var(--unit);

		@media #{base.$smobile} {
			grid-column: 1/4;
		}
		@media #{base.$mobile} {
			grid-column: 1/5;
		}
		@media #{base.$tablet} {
			grid-column: 1/10;
		}
		@media #{base.$desktop} {
			grid-column: 1/24;
		}
		@media #{base.$widescreen} {
			grid-column: 1/24;
		}
		@media #{base.$fullhd} {
			grid-column: 1/24;
		}

		display: flex;
		gap: var(--unit);

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet}, #{base.$desktop} {
			flex-flow: column;
		}
	}

	.message {
		h1 {
			@extend .headline;
		}
	}

	.channels {
		padding: 0 var(--unit) var(--unit) var(--unit);
		flex: 1;

		.body {
			p {
				margin: var(--unit) 0;
			}

			.button-wrapper {
				display: flex;
				gap: 2rem;

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					flex-flow: column;
				}

				.kyc-button.primary {
					background: var(--kyc-cybergreen);

					i,
					span {
						color: black;
					}

					&:hover {
						background: var(--kyc-normal-blue);

						i,
						span {
							color: white;
						}
					}
				}
			}
		}
	}

	.channels.get-in-touch {
		border-radius: var(--br24);
		border: 2px solid black;
		overflow: hidden;
	}

	.channels.message {
		display: flex;
		flex-flow: column;

		h1 {
			margin-bottom: 1rem;
		}

		h3 {
			margin-top: var(--unit);
		}

		a.kyc-button {
			margin-top: var(--unit);
		}
	}

	.channels.contact {
		border-radius: var(--br12);
		background: black;
		overflow: hidden;
		padding-top: var(--unit);

		h1 {
			font-family: var(--display-font);
			color: white;

			span {
				color: var(--kyc-cybergreen);
				margin-right: 0.5vw;
			}
		}

		p,
		h4 {
			color: white;
		}
	}
}
