@use "@Style/base";

.inner-links {
	a {
		@extend .text-sm;
	}
}

footer {
	/*
	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		padding: 1rem 0 4rem;
		max-width: calc(100% - 2rem);
		margin: 1rem auto;
	}

   */

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		background: black;
		padding: calc(var(--unit) / 2) var(--unit);
		position: relative;
		z-index: 1;
		margin-bottom: var(--unit);
		border-radius: var(--br24);

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			flex-flow: column;

			.footer-menu {
				margin-top: 1rem;
			}
		}

		.left,
		.right {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				margin-top: 1rem;
				padding-bottom: 1rem;
			}
		}
	}

	.left-container {
		display: flex;
		flex-flow: column;

		.copyright {
			margin-top: 1rem;
			margin-left: 2rem;

			p {
				color: white;
				font-family: var(--primary-font);
				text-decoration: none;
			}

			.small {
				@extend .text-xs;
				color: white;
			}
		}
	}

	.footer-logo {
		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			display: none;
		}

		svg {
			height: 3rem;
			width: auto;
			@extend .tr2;

			path {
				fill: var(--kyc-cybergreen);
				@extend .tr2;
			}

			&:hover {
				path {
					fill: white;
				}
			}
		}
	}

	.footer-menu {
		ul {
			display: flex;
			align-items: center;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: column;
			}

			li {
				margin-left: 2rem;

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					margin-left: 0;
					margin-bottom: 1rem;
				}

				a {
					font-family: var(--primary-font);
					font-weight: 400;
					color: white;
					text-decoration: none;
					@extend .tr2;

					&:hover {
						color: var(--kyc-cyberpunk);
						text-decoration: underline;
					}
				}
			}
		}
	}

	.footer-menu.social {
		ul {
			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				flex-flow: row;
			}

			li {
				margin: 0 0.5rem;

				a {
					height: 34px;
					width: 34px;
					display: block;
				}
			}
		}
	}
}
