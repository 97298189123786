@use "@Style/base";
@use "@Components/button/button.component";

p.contact-mail {
	position: relative;
	display: block;

	span {
		@extend .link-button;
		cursor: pointer;
	}
}

#contact-page {
	#hello-hero {
		overflow: visible;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			margin-top: 0;
		}

		.hero-content-wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 2/22;
			}
			@media #{base.$widescreen} {
				grid-column: 2/22;
			}
			@media #{base.$fullhd} {
				grid-column: 2/22;
			}
			display: flex;
			flex-flow: column;

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 1rem;
			}

			.headlines {
				display: flex;
				gap: var(--unit);
				justify-content: space-between;

				.text-wrapper {
					display: flex;
					flex-flow: column;
					gap: var(--unit);
					width: 100%;

					@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
					}

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						width: 100%;
					}
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						flex-flow: column;

						.kyc-button {
							width: 100%;
							display: inline-flex;
							justify-content: space-between;
						}
					}
				}

				p.partnership {
					position: relative;
					display: block;

					span {
						@extend .link-button;
					}
				}

				.image-wrapper {
					width: 100%;
					display: flex;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						display: none;
					}

					img {
						width: 100%;
					}
				}

				h1 {
					@extend .headline;
				}
			}

			.reasoning {
				margin-top: var(--unit);
				margin-bottom: calc(var(--unit) * -2);
				padding: var(--unit);
				border-radius: var(--br24);
				background: white;
				border: 2px black solid;

				h4 {
					margin: 1rem 0;
				}
			}
		}
	}

	#other-contact-channels {
		margin-bottom: var(--unit);

		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 1/10;
			}
			@media #{base.$desktop} {
				grid-column: 1/24;
			}
			@media #{base.$widescreen} {
				grid-column: 1/24;
			}
			@media #{base.$fullhd} {
				grid-column: 1/24;
			}

			display: flex;
			gap: var(--unit);

			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet}, #{base.$desktop} {
				flex-flow: column;
			}
		}

		.channels {
			padding: 0 var(--unit) var(--unit) var(--unit);
			flex: 1;

			.body {
				p {
					margin: var(--unit) 0;
				}

				.button-wrapper {
					display: flex;
					gap: 2rem;

					@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
						flex-flow: column;
					}

					.kyc-button.primary {
						background: var(--kyc-cybergreen);

						i,
						span {
							color: black;
						}

						&:hover {
							background: var(--kyc-normal-blue);

							i,
							span {
								color: white;
							}
						}
					}
				}
			}
		}

		.channels.get-in-touch {
			border-radius: var(--br24);
			border: 2px solid black;
			overflow: hidden;
		}

		.channels.support {
			border-radius: var(--br24);
			background: var(--bg-gray);
			overflow: hidden;
		}
	}
}
