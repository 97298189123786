@use "@Style/base";
@use "@Components/button/button.component";

#faq-page {
	p > a.link-button {
		&:visited {
			@extend .link-button;
		}

		&:hover {
			color: var(--kyc-normal-blue);
		}

		@extend .link-button;
	}

	.accordion-sample {
		display: flex;
		flex-flow: column;
		width: 100%;
		margin-bottom: 2rem;
		//margin-bottom: var(--unit);

		.item {
			background: transparent;
			height: auto;
			visibility: visible;
			border: 2px solid black;
			border-radius: var(--br12);
			@extend .tr2;

			& > div {
				background: transparent;
				border-radius: var(--br12);
			}

			.title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 2rem var(--unit);
				cursor: pointer;
				border-radius: var(--br12);
				@extend .tr2;

				i {
					cursor: pointer;
					height: var(--button-height);
					width: var(--button-height);
					display: flex;
					justify-content: center;
					align-items: center;
					background: black;
					color: white;
					border-radius: var(--br12);
					@extend .tr2;

					&:hover {
						background: var(--kyc-normal-blue);
						color: white;
					}
				}

				&:hover {
					background: var(--border);

					i {
						background: var(--kyc-normal-blue);
						color: white;
					}
				}
			}

			.content {
				border-top: 2px solid black;
				padding: 2rem var(--unit);
				@extend .tr2;
			}
		}

		.item.closed {
			height: 0;

			.title {
				background: white;
			}

			.content {
				height: 0;
				visibility: hidden;
				padding: 2rem var(--unit);
			}
		}

		.content {
			ul {
				li {
					list-style: initial !important;
					margin-top: 0.5rem;
					margin-left: 2.5rem;
					@extend p;
				}
			}

			a {
				@extend .link-button;
			}

			b {
				font-weight: 800;
			}
		}
	}
}
