@use "../../components/button/button.component";
@use "@Style/base";

.modal.default.big {
	display: none !important;
}

#hero.large {
	.sub-headline {
		grid-column: 1/24;

		h2 {
			font-family: var(--light-font);
		}
	}

	h2 {
		padding-top: var(--unit);
	}

	.hero-content-wrapper {
		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			padding: 10rem 0 2rem !important;
		}
	}
}

#our-values {
	.the-values-list {
		grid-column: 1/24;
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		li {
			//padding-right: var(--unit);
			background: white;
			padding: calc(var(--unit) / 2);
		}

		li:first-child {
			border-top: 3px solid var(--kyc-normal-blue);
		}

		li:nth-child(2) {
			border-top: 3px solid var(--kyc-dark-blue);
		}

		li:nth-child(3) {
			border-top: 3px solid var(--kyc-green);
		}
	}

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		.the-values-list {
			display: flex;
			flex-flow: column;

			li {
				padding: 1rem;
			}
		}
	}
}

#partners-page {
	.button-wrapper {
		margin-top: 2rem;

		.download-button {
			@extend .kyc-button;
			@extend .blue;
			width: max-content;
		}
	}

	@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
		.button-wrapper {
			grid-column: 1/24;

			.download-button {
				width: 100%;
			}
		}
	}

	#above-the-fold {
		.logo-outlined {
			opacity: 0.5;
		}
	}

	.narrow > {
		.header {
			display: flex;
			align-items: center;
			margin-bottom: 2rem;

			i {
				color: white;
				padding: 1rem;
				background: var(--kyc-normal-blue);
				border-radius: 999rem;
				outline: 10px solid var(--kyc-normal-blue-15);
			}

			h4 {
				padding-left: 2rem;
			}
		}

		.header,
		p {
			@media #{base.$smobile} {
				grid-column: 1/4;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
			}
			@media #{base.$desktop} {
				grid-column: 2/18;
			}
			@media #{base.$widescreen} {
				grid-column: 2/18;
			}
			@media #{base.$fullhd} {
				grid-column: 2/18;
			}

			line-height: 170%;
		}
	}

	.container.narrow {
		ul {
			grid-column: 1/24;
			padding-top: 2rem;

			li {
				margin-left: calc(var(--unit) * 2);
				list-style: disc;
				@extend p;
			}
		}
	}

	#inner-title {
		.wrapper {
			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				grid-column: 1 / 24;
			}

			@media #{base.$desktop} {
				grid-column: 1 / 12;
			}

			@media #{base.$widescreen}, #{base.$fullhd} {
				grid-column: 1 / 8;
			}
		}

		h2 {
			margin-bottom: 1rem;
		}

		p {
		}
	}

	#careers-section {
		grid-column: 1/24;
		display: flex;
		flex-flow: column;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			padding: 1rem;
		}

		@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
			padding: calc(var(--unit) * 2);
		}

		.title {
			@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
				padding: 6rem 1rem 4rem 1rem;
				grid-column: 1/24;
			}

			@media #{base.$desktop} {
				padding: 8vh 0 4vh 0;
				grid-column: 3/21;
			}

			@media #{base.$widescreen}, #{base.$fullhd} {
				padding: 10vh 0 6vh 0;
				grid-column: 3/21;
			}

			i {
				padding: 1rem;
				background: var(--kyc-normal-blue);
				border-radius: 999rem;
				margin-bottom: 1rem;
				outline: 10px solid var(--kyc-dark-blue-35);
			}

			i,
			h2,
			h4 {
				color: white;
			}

			h4 {
				margin-top: 1rem;
			}
		}

		ul.careers-listing {
			&.grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: var(--unit);

				@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
					display: flex;
					flex-flow: column;

					li {
						padding: 1rem;

						button {
							padding-left: 1rem;
							padding-right: 1rem;

							span {
								font-size: 16px;
							}
						}
					}
				}

				li {
					//border: 1px solid white;
					background: var(--kyc-dark-blue-35);
					padding: 2rem;
					display: flex;
					flex-flow: column;
					justify-content: space-between;

					.tag {
						padding: 4px 10px;
						background: var(--kyc-normal-blue);
						color: white;
						display: inline-flex;
					}

					.job-title {
						display: flex;
						justify-content: space-between;

						h4 {
							span {
								color: var(--kyc-green);
							}
						}
					}

					.smaller-text {
						font-size: 18px;
						line-height: 130%;
					}

					h4 {
						color: white;
						margin-bottom: 2rem;
					}

					p {
						color: white;
					}

					button {
						margin-top: 2rem;
						width: max-content;
					}
				}
			}
		}

		.footer {
			margin-top: 2rem;

			h4 {
				color: white;

				span {
					display: inline-block;
					box-sizing: border-box;
					padding: 0;
					position: relative;
					margin-left: 0.5rem;

					&:after {
						position: absolute;
						bottom: 0px;
						height: 9px;
						width: 100%;
						background-color: var(--kyc-green-50);
						content: "";
						display: block;
						@extend .tr2;
					}

					&:hover {
						//background-color: var(--kyc-normal-blue-35);
						color: var(--kyc-green);

						&:after {
							background-color: var(--kyc-green-75);
						}
					}
				}
			}
		}
	}

	#application-process {
		.wrapper {
			@media #{base.$smobile} {
				grid-column: 1/3;
				padding: 2rem 1rem;
			}
			@media #{base.$mobile} {
				grid-column: 1/5;
				padding: 4rem 1rem;
			}
			@media #{base.$tablet} {
				grid-column: 2/8;
				padding: 4rem 0;
			}
			@media #{base.$desktop} {
				grid-column: 5/19;
			}
			@media #{base.$widescreen} {
				grid-column: 5/19;
			}

			@media #{base.$fullhd} {
				grid-column: 3/21;
			}
		}

		h2 {
			text-align: center;
			margin-bottom: 4rem;
		}

		.the-process {
			display: flex;

			li {
				display: flex;
				flex-flow: column;
				text-align: center;
				align-items: center;
				width: 100%;

				span {
					padding: 1rem;
					background: var(--kyc-normal-blue);
					color: white;
					border-radius: 999rem;
					width: 1rem;
					height: 1rem;
				}

				p {
				}
			}

			@media #{base.$smobile}, #{base.$mobile} {
				flex-flow: column;

				li {
					margin-bottom: 1rem;
				}
			}
		}
	}
}
