@use "@Style/base/animations";

:host {
	// z-index: 100;
	height: 100%;
	display: flex;

	h4 {
		//margin-top: var(--unit);
	}

	.form-wrapper {
		position: relative;
	}
}

.notification {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;

	position: absolute;
	top: -6rem;
	//top: 0rem;
	z-index: 10;

	&:after {
		display: block;
		content: "";
		width: 18px;
		height: 18px;
		position: absolute;
		bottom: -10px;
		transform: rotate(45deg);
		left: 17px;
	}

	& > div {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;

		p {
			//@extend .text-sm;
			font-size: 18px;
		}

		span {
			font-size: 16px;
			font-family: gilroyRegular;
		}
	}

	.icon {
		background: white;
		border-radius: 999rem;
		padding: 3px;
		margin-right: 0.5rem;
	}

	.close {
		cursor: pointer;
		padding: 3px;
		color: var(--kyc-dark-blue);
		margin-left: 0.5rem;
		border-radius: 999rem;
		@extend .tr2;

		&:hover {
			background: var(--kyc-dark-blue);
			color: white;
		}
	}
}

.error-label {
	background: var(--kyc-red);
	border: 5px solid var(--kyc-red);

	&:after {
		background: var(--kyc-red);
	}

	p,
	span {
		color: var(--kyc-dark-blue);
	}

	.icon {
		color: var(--kyc-red);
		border: 3px solid var(--kyc-red-darken);
	}

	.close {
	}
}

.passed {
	background: var(--kyc-green);
	border: 5px solid var(--kyc-green-darken);

	&:after {
		background: var(--kyc-green);
	}

	p,
	span {
		color: var(--kyc-dark-blue);
	}

	.icon {
		//color: var(--kyc-green);
		color: var(--kyc-dark-blue);
		border: 3px solid var(--kyc-green-darken);
	}

	.close {
	}
}
