/* TRANSITIONS */

.tr2 {
	transition-property: all;
	transition-duration: 0.2s;
}

@mixin tr($transition-speed) {
	transition-property: $transition-speed;
	transition-duration: $transition-speed;
}

@mixin rotate($rotation) {
	transition-property: $rotation;
	transform: rotate($rotation);
}

@mixin delay($delay-speeed) {
	animation-delay: $delay-speeed;
}

@mixin bezier-eas-out($speed) {
	transition: all $speed cubic-bezier(0.36, 0.26, 0.47, 0.9);
}

@mixin scale($scaleRate) {
	transform: scale($scaleRate, $scaleRate);
}

@mixin translateX($translateX) {
	transform: translateX($translateX);
}

@mixin translateY($translateY) {
	transform: translateY($translateY);
}
