@use "@Style/base";
@use "@Components/button/button.component";

#privacy-policy {
	h4 > a.link-button {
		&::after {
			bottom: -9px;
			height: 0px;
		}
		&:hover {
			&::after {
				height: 9px;
				bottom: -9px;
			}
		}
		margin-bottom: 0.5em;
	}

	.text-wrapper {
		display: flex;
		flex-flow: column;
		width: 100%;
		grid-column: 1 / 24;

		ul {
			//margin: 1rem;

			li {
				list-style: initial;
			}
		}

		p > a.link-button {
			@extend .link-button;
			&:visited {
				@extend .link-button;
				color: black;
			}

			&:hover {
				color: var(--kyc-normal-blue);
			}

			@extend .link-button;
		}

		ol,
		ul,
		p {
			font-weight: 400;
			font-family: var(--primary-font);

			b {
				line-height: 3em;
				font-family: var(--primary-font);
				font-weight: 800;
			}
		}

		a {
			display: inline-block;
			box-sizing: border-box;
			padding: 0;
			position: relative;
			@extend .tr2;

			&:after {
				position: absolute;
				bottom: 0px;
				height: 9px;
				width: 100%;
				background-color: var(--kyc-normal-blue-35);
				content: "";
				display: block;
				@extend .tr2;
			}

			&:hover {
				//background-color: var(--kyc-normal-blue-35);
				color: var(--kyc-normal-blue);

				&:after {
					background: var(--kyc-normal-blue);
				}
			}

			&:visited {
				color: var(--kyc-normal-blue);

				&:after {
					background: var(--kyc-green-darken);
				}
			}
		}

		ol {
			@media #{base.$desktop},
      #{base.$widescreen},
      #{base.$fullhd} {
				margin-left: var(--unit);
			}

			@media #{base.$smobile},
      #{base.$mobile},
      #{base.$tablet} {
				margin-left: 1rem;
			}

			li {
				margin-top: 1.5rem;
				@extend p;

				ul {
					@media #{base.$desktop},
          #{base.$widescreen},
          #{base.$fullhd} {
						margin-left: var(--unit);
					}

					@media #{base.$smobile},
          #{base.$mobile},
          #{base.$tablet} {
						margin-left: 1rem;

						li {
						}
					}
				}

				ol {
					li {
						margin-top: 1.5rem;

						ol {
							li {
								list-style: disc;
								counter-reset: none;
							}
						}
					}
				}
			}
		}

		ol > li:before {
			content: counters(item, ".") ".";
			counter-increment: item;
			margin-left: -16px;
			padding: 10px;
			background: black;
			color: white;
			margin-right: 10px;
			font-family: var(--display-font);
		}

		ol > li > ol li:before {
			margin-left: -36px !important;
			line-height: 3em;
		}

		ol > li > ol > li > ul li {
			list-style: disc;
			counter-reset: none;

			&:before {
				display: none !important;
			}
		}

		ol {
			counter-reset: item;

			li {
				display: block;
			}
		}
	}
}
