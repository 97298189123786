@use "@Style/base";

#hero {
	background-image: url("~@Assets/img/kycd-pearl-background.jpg");
	background-size: cover;
	margin: 0 var(--unit) 0 var(--unit);
	z-index: 1;
	position: relative;
	border-radius: var(--br24);
	@extend .tr2;

	//left: var(--unit);
	//right: var(--unit);
	//top: var(--unit);
	//position: relative;

	h1,
	h2 {
		@extend .headline;
		position: relative;
	}

	h2 {
		display: none;
	}

	h4 {
		grid-column: 1 / 24;
	}

	.headline-wrapper {
		overflow: hidden;

		@media #{base.$smobile}, #{base.$mobile} {
			grid-column: 1 / 24;
			padding-top: var(--unit);
		}

		@media #{base.$tablet}, #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
			grid-column: 1 / 24;
		}

		h1 {
			position: relative;
			//top: 120%;
			transition: 0.3s cubic-bezier(0.17, 0.62, 0.82, 0.67);
		}
	}

	.illustration-wrapper {
		position: absolute;
		top: 1rem;
		right: 1rem;

		@media #{base.$smobile}, #{base.$mobile} {
			left: 0;
			width: 100%;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}

	@media #{base.$smobile}, #{base.$mobile} {
		padding-top: 3rem;
	}
	@media #{base.$tablet}, #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		padding-top: 5rem;
	}

	&.large {
		.hero-content-wrapper {
			@media #{base.$mobile}, #{base.$tablet} {
				padding: 6rem 0 6rem 0;
			}

			@media #{base.$desktop} {
				padding: 10vh 0 10vh 0;
			}

			@media #{base.$widescreen}, #{base.$fullhd} {
				padding: 18vh 0 18vh 0;
			}
		}
		img {
			display: none;
		}
	}

	&.small {
		.hero-content-wrapper {
			padding-top: 10vh;
			padding-bottom: 10vh;
		}
		img {
			display: none;
		}
	}

	&.home {
		.hero-content-wrapper {
			@media #{base.$mobile}, #{base.$tablet} {
				padding: 6rem 0 6rem 0;
			}

			@media #{base.$desktop} {
				padding: 10vh 0 10vh 0;
			}

			@media #{base.$widescreen}, #{base.$fullhd} {
			}
		}
		img {
			display: block;
		}
	}
}

#hero.home {
	margin-top: 0;

	.hero-content-wrapper {
		row-gap: 1rem;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			padding: 6rem 0 6rem 0;
		}

		@media #{base.$desktop} {
			padding: 14vh 0 12vh 0;
		}

		@media #{base.$widescreen}, #{base.$fullhd} {
			padding: 22vh 0 38vh 0;
			margin-bottom: -24vh;
		}

		h1 {
			grid-column: 1 / 12;
		}

		h2 {
			grid-column: 1 / 24;
		}
	}
}

#hero.app-page {
	.hero-content-wrapper {
		row-gap: 1rem;

		@media #{base.$smobile}, #{base.$mobile}, #{base.$tablet} {
			padding: 6rem 0 6rem 0;
		}

		@media #{base.$desktop} {
			padding: 14vh 0 12vh 0;
		}

		@media #{base.$widescreen}, #{base.$fullhd} {
			padding: 22vh 0 38vh 0;
			margin-bottom: -24vh;
		}

		h1 {
			grid-column: 1 / 12;
		}

		h2 {
			grid-column: 1 / 24;
		}
	}

	img {
		display: none;
	}

	.headline-wrapper {
		display: flex;
		gap: 1px;
		flex-flow: column;

		h2 {
			font-family: var(--display-font) !important;
			display: inline;
			@extend .headline;
			@extend .xxl-text;
		}

		h1,
		h2 {
			width: fit-content;
		}
	}
}
