@use "@Style/base";

.kyc-button {
	display: inline-flex;
	justify-content: space-between;
	padding-left: 2rem;
	padding-right: 2rem;
	align-items: center;
	outline: none;
	border: 1px solid transparent;
	cursor: pointer;
	width: max-content;
	border-radius: var(--br10);
	height: var(--button-height);
	@extend .button-size;
	position: relative;
	transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);

	@media #{base.$desktop}, #{base.$widescreen}, #{base.$fullhd} {
		min-height: 3rem;
	}

	span {
		font-family: var(--display-font);
		font-weight: 200;
		margin-left: 0;
		@extend .md-text;
	}

	.nav {
		width: 0;
		opacity: 0;
		visibility: hidden;
		transform: translateX(-1rem);
		@extend .tr2;
	}

	.arrow {
		@include base.rotate(0);
		@extend .tr2;
		margin-left: 1rem;
	}

	&.primary {
		background: var(--kyc-normal-blue);
		border-radius: var(--border-radius-light);
		transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
		box-shadow: black 4px 4px 0px 0px;
		border: 2px solid black;

		i,
		span {
			color: white;
			transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
		}

		i {
			left: 0;
		}

		span {
			font-family: var(--primary-font);
			text-transform: none;
		}

		&:hover {
			background: var(--kyc-cybergreen);

			i,
			span {
				color: black;
			}

			i {
				left: 1rem;
			}
		}

		&:focus {
		}

		&:active {
			box-shadow: black 0px 0px 0px 0px;
		}
	}

	&.secondary {
		background: transparent;
		border-radius: 0px;
		padding-left: 0;
		padding-right: 0;
		border-bottom: 2px solid black;
		border-left: 2px solid transparent;
		border-top: 2px solid transparent;
		border-right: 2px solid transparent;
		transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);

		span {
			font-family: var(--primary-font);
			text-transform: none;
		}

		span,
		i {
			color: black;
		}

		/*
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: black;
      z-index: -1;
      opacity: 0;
      transition: 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
    }

    */

		&:hover {
			background: black;
			border-left: 2px solid black;
			border-top: 2px solid black;
			border-right: 2px solid black;

			&:after {
				width: 100%;
				opacity: 1;
			}

			span,
			i {
				color: white;
			}
		}
	}

	&.black {
		background: transparent;
		border: 2px solid black;
		box-shadow: 0 0 0 0 rgba(white, 0);
		position: relative;
		transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);

		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: black;
			z-index: 1;
			transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);
			//@include tr(0.35s);
		}

		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			width: 0;
			background-color: black;
			z-index: 1;
			transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);
		}

		span,
		.material-icons {
			position: relative;
			color: white;
			z-index: 10;
			transition: 0.35s cubic-bezier(0.66, 0.01, 0.17, 1);
		}

		&:hover {
			background: transparent;
			border: 2px solid black;

			//box-shadow: 0 0 0 10px black;
			//box-shadow: 0 0 10px 10px var(--kyc-cyberpunk-35);

			&:after {
				top: 52px;
			}

			span,
			.material-icons {
				color: black;
			}
		}

		&:focus {
			box-shadow: 0 0 0 4px rgba(black, 0.35);
		}

		&:active {
			&:before {
				width: 100%;
				//right: 0;
			}

			span,
			.material-icons {
				color: white;
			}

			.arrow {
				transform: translateX(10px);
			}
		}

		&:focus-visible {
			outline: none;
			border: none;
		}
	}

	&.full-width {
		display: flex;
		width: 100%;
	}
}

.link-button {
	box-sizing: border-box;
	padding: 0;
	position: relative;
	display: inline-block;
	z-index: 10;
	@extend .tr2;

	&:after {
		position: absolute;
		bottom: 0px;
		height: 2px;
		width: 100%;
		background-color: var(--kyc-normal-blue-35);
		content: "";
		display: block;
		z-index: -1;
		@extend .tr2;
	}

	&:hover {
		color: var(--kyc-normal-blue);

		&:after {
			height: 9px;
			background: var(--kyc-normal-blue-35);
		}
	}

	&:active {
		background: var(--kyc-cybergreen);

		&:after {
			height: 100%;
			background: black;
		}
	}

	&:visited {
		color: var(--kyc-normal-blue);

		&:after {
			background: var(--kyc-green-darken);
		}
	}
}
